import React from "react";
import classes from "./index.module.css";
const MultipleOptionsAndYesOrNoQuestion = ({
  questionType,
  junkeeAdminQuestions,
  options,
  mediasC,
  medias,
  handleClick,
  notify,
  selectedOption,

  htmlcss,


}) => {

  const renderImage = mediasC || medias;

  let backgroundMediaType;
  if (renderImage && renderImage.length >= 1) {
    backgroundMediaType = renderImage[0].mediaType;
  }

  const Options =
    Object.keys(junkeeAdminQuestions).length >= 1 &&
    options
      .sort((a, b) => a.id - b.id)
      .map((data, index) => {

        return (

          <div key={index}>

            <button
              type="button"
              onClick={() => {
                handleClick(data.id);
                // notify();
              }}

              style={
                data.id === selectedOption
                  ? htmlcss && htmlcss.selectedoption
                  : {}
              }


              className={
                questionType == "multiple_choice"
                  ? `${classes.button} ${data.id == selectedOption ? classes.active_option : ""
                  }`
                  : `${classes.yes_button} ${data.id == selectedOption ? classes.active_option : ""
                  }`
              }
            >

              <div
                className={
                  questionType == "yes_or_no"
                    ? classes.yes_or_no_text
                    : classes.multiple_text
                }
                style={htmlcss && htmlcss.font}
              ></div>
              {questionType == "multiple_choice" && (
                <span
                  className={
                    questionType == "multiple_choice"
                      ? `${classes.sno} ${data.id == selectedOption
                        ? classes.active_option_span
                        : ""
                      }`
                      : `${classes.yes_button} ${data.id == selectedOption ? "" : ""
                      }`
                  }
                >
                  <div
                    className={classes.index}
                    style={htmlcss && htmlcss.font}
                  >
                    {" "}
                    {index + 1}
                  </div>
                </span>
              )}
              <span
                className={
                  questionType == "yes_or_no" ? classes.text_with_border : ""
                }
                style={htmlcss && htmlcss.font}
              ></span>{" "}
              <div
                className={
                  questionType == "multiple_choice"
                    ? classes.multiple_choice_alignment
                    : classes.yes_or_no_text
                }
                style={htmlcss && htmlcss.font}
              >
                <> {data.text}</>
              </div>
            </button>
          </div>
        );
      });
  return (
    <>
      {backgroundMediaType == "video" ? (
        <>
          <div className={classes.overlay}></div>
          <video src={mediasC[0].mediaSource} autoPlay loop muted />
          <div className={classes.content}>
            <div
              className={
                questionType == "multiple_choice"
                  ? classes.question
                  : classes.yes_no_question
              }
              style={htmlcss && htmlcss.font}
            >
              {Options}
            </div>
          </div>
        </>
      ) : (
        <div
          className={
            questionType == "multiple_choice"
              ? classes.multiple_type_question_image
              : classes.question_img
          }
          style={{
            background:
              questionType == "yes_or_no" &&
                Object.keys(junkeeAdminQuestions).length >= 1 &&
                renderImage.length > 0
                ? `url(${renderImage[0].mediaSource})`

                : htmlcss && htmlcss.question != null
                  ? htmlcss && htmlcss.question.background
                  : "linear-gradient(254.59deg, #4cc9f0 -3.55%, #4895ef 14.74%, #560bad 44.39%,#b5179e 74.54%,#f72585 91.34%)",

            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <div
            className={
              questionType == "multiple_choice"
                ? classes.question
                : classes.yes_no_question
            }
            style={htmlcss && htmlcss.font}
          >
            {Options}
          </div>
        </div>
      )}
    </>
  );
};
export default MultipleOptionsAndYesOrNoQuestion;
