

import { useState, useEffect } from "react";
import {getOrganizationDtlAccordingToDomain} from "../utils/helpers";
 

  
export const useAnonymousStatusOrganization = () => {
    const [anonymousStatus, setAnonymousStatus] = useState(null);
     
    async function fetchData() {
        try {
          const res = await getOrganizationDtlAccordingToDomain(); 
          
          if (res &&  res.anonymous_votestatus != null) {
            setAnonymousStatus(res.anonymous_votestatus);
            
          }
        } catch (error) {
          console.error(error);
        }
      }

    useEffect(() => { 
    
      fetchData();
    }, []);
  
    return anonymousStatus;
  };
  