import React, { useState, useEffect } from "react";
import MainContainer from "./Container/index";
import { getParentUrl } from "./utils/GetParentUrl";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getOrganizationDtlAccordingToDomain,
  getPortalTypeAccordingToUrl,
} from "./utils/helpers";
import Loading from "./Screens/Loading";
import { getPostUnauthenticated } from "./api/Post";
import Error404 from "./Screens/404";

// Import prerender-node and configure it
if (typeof window !== 'undefined') {
  require('prerender-node').set('prerenderToken', 'ICQ13TmUWdpZQzoaUEke');
}


const App = () => {
  const parentUrlRef = getParentUrl();
  const childUrl = document.location.href;
  const urlParts = parentUrlRef.split("/");
  const wineLastPart = urlParts[urlParts.length - 2];
  const wizerLastPart = urlParts[urlParts.length - 1];
  const [portal, setPortal] = useState("");
  const [showMainContainer, setShowMainContainer] = useState(false);
  const [unAuthpost, setAuthUnpost] = useState(null);
  const [HtmlCss, setHtmlCss] = useState({});
  const tagPart = portal === "wizer" ? wizerLastPart : wineLastPart;
  const postIdMatch = tagPart && tagPart.match(/postId=([^&]+)/);

  const urlParams = new URLSearchParams(new URL(childUrl).search);
  const postId = parseInt(urlParams.get("postId"))

  useEffect(() => {
    fetchCssData();
    fetchPortalName();
    const timer = setTimeout(() => {
      setShowMainContainer(true);
    }, 1000);
    if (tagPart) {
      localStorage.setItem("getTagForPost", tagPart);
    }
    if (postId) {
      fetchPostData();
    }
    return () => clearTimeout(timer);
  }, [postId, tagPart]);
  

  const fetchCssData = async () => {
    try {
      const res = await getOrganizationDtlAccordingToDomain();
      if (res && res.dictionary != null) {
        setHtmlCss(res.dictionary[0].webapp);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchPortalName = async () => {
    try {
      const portalName = await getPortalTypeAccordingToUrl();
      setPortal(portalName);
    } catch (error) { }
  };

  const fetchPostData = async () => {
    try {
      const data = await getPostUnauthenticated({
        postId: postId,
      });

      setAuthUnpost(data);
    } catch (error) {
      console.log(error);
    }
  };

  let baseUrl;
  try {
    const urlObj = new URL(parentUrlRef);
    baseUrl = `${urlObj.origin}${urlObj.pathname}`;
  } catch (error) {
    console.error("Invalid URL:", parentUrlRef);
  }

  const orgId = localStorage.getItem("organization_id");

  if (orgId === null || orgId === "undefined") {
    return <Error404 />;
  }
  return (
    <div className="App">
      {showMainContainer ? (
        <MainContainer
          htmlcss={HtmlCss}
          postIdVal={postId}
          unAuthpost={unAuthpost}
          parentUrlRef={parentUrlRef}
          baseUrl={baseUrl}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default App;
