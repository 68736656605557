import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./index.module.css";
import BackButton from "../../assets/icons/white.svg";
const PrivacyContent = ({ setType }) => {
  return (
    <Container className={classes.bg}>
      <Row>
        <Col xs={9} md={10}>
          <div className={classes.back_button}>
            <button
              type="button"
              onClick={() => {
                setType(3);
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <img src={BackButton} alt="back button" />
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <div className={classes.privacy}>
            <h3 className={classes.heading}>WIZER PRIVACY POLICY</h3>
            <p className={classes.paragraph}>
              <b>Date: </b>28 Sep 2022
            </p>
            <h3 className={classes.heading}>IN A NUTSHELL | TL;DR</h3>
            <p className={classes.paragraph}>
              Greenlight Technologies Pty Ltd ABN 47 652 995 349 (we or us or
              our) built the Wizer app as a tool designed to help people make
              better decisions by using a wise crowd (6 or more people).{" "}
            </p>
            <p className={classes.paragraph}>
              We are committed to protecting your privacy as a person who uses
              the Wizer app. This Privacy Policy tells you about how we collect,
              use and share your personal information. You can also find
              information on how to manage your personal information and your
              rights in relation to the personal information you have provided
              when accessing the Wizer app and our associated website and
              services (Services). This website may be accessed in Australia and
              overseas.
            </p>
            <p className={classes.paragraph}>
              By accessing our Services, you agree that you understand the terms
              of this Privacy Policy and you give your consent to our
              collection, use and disclosure of your personal information or
              personal data (personal information) in accordance with this
              Privacy Policy.
            </p>
            <p className={classes.paragraph}>
              The collection, use and disclosure of your personal information is
              governed by the Privacy Act 1988 (Cth) if you are in Australia,
              the General Data Protection Regulations of the EU or UK (GDPR) if
              you are in Europe or the UK, or similar legislation applicable in
              the place where you live. For data protection purposes we are the
              controller and, unless otherwise noted, also the processor of
              data.
            </p>
            <h3 className={classes.heading}>OUR FULL POLICY</h3>
            <p className={classes.paragraph}>
              Greenlight Technologies Pty Ltd ABN 47 652 995 349 (we or us or
              our) built the Wizer app as a tool designed to help people make
              better decisions by using a wise crowd (6 or more people). We are
              committed to protecting your privacy as a person who uses the
              Wizer app. This Privacy Policy tells you about how we collect, use
              and share your personal information. You can also find information
              on how to manage your personal information and your rights in
              relation to the personal information you have provided when
              accessing the Wizer app and our associated website and services
              (Services). This website may be accessed in Australia and
              overseas. By accessing our Services, you agree that you understand
              the terms of this Privacy Policy and you give your consent to our
              collection, use and disclosure of your personal information or
              personal data (personal information) in accordance with this
              Privacy Policy. The collection, use and disclosure of your
              personal information is governed by the Privacy Act 1988 (Cth) if
              you are in Australia, the General Data Protection Regulations of
              the EU or UK (GDPR) if you are in Europe or the UK, or similar
              legislation applicable in the place where you live. For data
              protection purposes we are the controller and, unless otherwise
              noted, also the processor of data.
            </p>
            <h3 className={classes.heading}>
              Collection of Personal Information
            </h3>

            <p className={classes.paragraph}>
              What we may collect : We may collect personal information that can
              be used to identify you. This personal information may include
              your name, email address, telephone number , username and
              password, gender, postcode, financial information such as credit
              card number and billing address, and profession, occupation or job
              title. Additionally, we may also collect photographs and/or images
              of you.
            </p>
            <p className={classes.paragraph}>
              When we collect personal information: We will collect some
              information from you when you download the Wizer app or otherwise
              access our Services or when you sign up for an account.
            </p>

            <p className={classes.paragraph}>
              We may also collect information from you when you share
              information with other users of our Services, for example, by
              answering questions that other users have asked.
            </p>
            <p className={classes.paragraph}>
              How we collect information: We may collect personal information
              directly from you such as when you sign up for an account or deal
              with us online or by email. We may also collect personal
              information indirectly from third parties or publicly available
              information.
            </p>

            <p className={classes.paragraph}>
              If you give us personal information about another person, you must
              have the right to disclose that personal information to us.
            </p>
            <p className={classes.paragraph}>
              Collection of personal information via third party accounts: If
              you decide to create an account so that you can use the Services,
              you will be given the choice of joining via an email account,
              Google or Apple (third party accounts). We will collect from the
              third party account, information that you have agreed to make
              available to us provided that it is in accordance with the privacy
              policy of the third party account. If you want to change the
              personal information we receive from the third party account, you
              will need to go to that third party account.
            </p>
            <p className={classes.paragraph}>
              If you do not want to give us personal information: You can choose
              not to provide personal information, but this means certain
              products and services may not be available to you or may not have
              the functionality that you would otherwise get. You can withdraw
              your consent at any time by contacting us at{" "}
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
            </p>
            <p className={classes.paragraph}>
              Sensitive information: We will not ask you to provide sensitive
              information eg details of political affiliations, religious
              beliefs, sexual preferences or health information. We may ask you
              to provide details of your racial or ethnic origin which can
              influence your decision making profile. If you choose to provide
              any of this information when answering a question on the Wizer
              app, you will be taken to have opted in to its collection, use and
              disclosure in accordance with this Privacy Policy.
            </p>
            <p className={classes.paragraph}>
              Automatically collected information: We use cookies and other
              technologies to automatically collect information whenever you use
              our Services.
            </p>
            <p className={classes.paragraph}>
              This includes your IP address, browser type and language, access
              times and the website that referred you to the Services. We may
              also collect navigational information, including information about
              the pages you view, the links you click, and other actions taken
              in connection with the Services. We may combine your visit and
              navigational information with the personal information that you
              provided.
            </p>
            <p className={classes.paragraph}>
              We use "cookies" to enable you to sign in to the Services and to
              help personalise your online experience. A cookie is a small text
              file that is placed on your hard drive. Cookies contain
              information, including personal information that can later be read
              by a web server in the domain that issued the cookie to you. The
              information that cookies collect includes the date and time of
              your visit, your registration information, and your navigational
              and purchase history. Cookies offer you many conveniences
              including saving you time by eliminating the need to repeatedly
              enter the same information.
            </p>
            <p className={classes.paragraph}>
              In some cases, our third-party service providers may use cookies
              on the Wizer app or our website. We have no access to or control
              over these cookies.
            </p>
            <p className={classes.paragraph}>
              You have the ability to accept or refuse cookies. Most browsers
              automatically accept cookies, but you can usually modify your
              browser setting to refuse cookies. If you choose to refuse
              cookies, you may not be able to sign in or use other interactive
              features of the Services that depend on cookies.
            </p>

            <p className={classes.paragraph}>
              In addition to cookies, we may use other technologies, including
              single-pixel gifs (also known as web beacons). These tiny
              electronic images assist us in determining how many users have
              accessed the Services. We do not use these images to collect
              personal information.
            </p>
            <p className={classes.paragraph}>
              When you use our Services, if there is an error when you use the
              Wizer app, we collect the data and information, through third
              party products, on your device called log data. The log data may
              include information such as your device’s Internet Protocol (IP)
              address, device name, operating system version, the configuration
              of the Wizer app when you used our Services, the time and date of
              our use of the Services and other statistics. This information is
              not used or retained by us.
            </p>
            <h3 className={classes.heading}>
              Use of Your Personal Information
            </h3>
            <p className={classes.paragraph}>
              Why we use your personal information: The main reason we use your
              personal information is to help us curate wise crowds and provide
              wise answers to the questions that users ask. Your personal
              information helps us do this and benefits other users of our
              Services.
            </p>
            <p className={classes.paragraph}>
              We use your personal information generally to provide the Services
              and for purposes which are connected to providing the Services.
              Such purposes include:
            </p>
            <ul>
              <li>
                {" "}
                doing business with you so that you can access and purchase the
                Services;
              </li>{" "}
              <li>effectively communicating with you;</li>{" "}
              <li>operating and improving the Services;</li>{" "}
              <li>providing customer service;</li>{" "}
              <li>
                performing research and analysis aimed at improving our
                Services;
              </li>{" "}
              <li>
                displaying content that is customised to your interests and
                preferences; and
              </li>
              <li>
                obtaining the services of analytics and search engine providers
              </li>{" "}
            </ul>
            <p className={classes.paragraph}>
              Interactions with other users: Our collection and use of your
              personal information will, depending on your subscription level,
              allow you to interact with other users of the Services, share your
              expertise, answer questions asked by other users and examine the
              depth and details of the decisions you have made.
            </p>
            <p className={classes.paragraph}>
              Targeted marketing: We may use your personal information and other
              information we have obtained about you and your interaction with
              other users, to provide you with the information about other
              products or services that might interest you. If you are a paid
              subscriber of our Services, you will be able to turn off targeted
              marketing.
            </p>

            <p className={classes.paragraph}>
              Aggregated information: You consent to us aggregating your
              information with the information of other users of the Services to
              share with users to assist them to make decisions or purchase
              goods or services that are best suited to them.
            </p>
            <h3 className={classes.heading}>
              Sharing of Your Personal Information
            </h3>
            <p className={classes.paragraph}>
              No dealing with information: We do not sell, rent or trade your
              personal information to or with any third parties.
            </p>

            <p className={classes.paragraph}>
              Service providers: You consent to us sharing your personal
              information with external service providers who provide services
              on us. For example, we may hire other businesses to handle the
              processing of payments, provide data storage, host websites,
              fulfil orders, assist in direct marketing, conduct audits, etc. We
              only give those businesses the personal information they need to
              provide the service. We will take all reasonable steps to make
              sure they comply with the same privacy principles that govern our
              collection and use of your personal information.
            </p>
            <p className={classes.paragraph}>
              Required disclosure : We may disclose your personal information if
              required to do so by law, or in the good-faith belief that such
              action is reasonably necessary to comply with legal process,
              respond to claims, or protect the rights, property or safety of
              our company, employees, customers, or the public.
            </p>

            <h2>Security and storage of your personal information</h2>
            <p className={classes.paragraph}>
              Storage of your personal information: We will store all of your
              personal information on servers hosted in Australia. Some of our
              external service providers may be overseas. While we will do our
              best to make sure that overseas service providers to whom we give
              your personal information will comply with the same privacy
              principles as us, we cannot guarantee it. By giving us your
              personal information, you consent to the disclosure of personal
              information to overseas service providers who may not comply.
            </p>
            <p className={classes.paragraph}>
              If the EU or UK GDPR applies: We and some of our external service
              providers may not be in the country where you submitted your
              personal information. As a result, your personal information may
              be transferred to countries that have different levels of data
              protection laws to where you are. Where local data regulations
              require it, we have done our best to (a) put in place security
              measures for the export of personal information from its country
              of origin; and (b) made arrangements with those receiving your
              personal information, that they will put security measures in
              place and that your personal information is processed in
              accordance with EU and UK data protection laws.
            </p>

            <p className={classes.paragraph}>
              Security of your personal information: We follow accepted industry
              standards to help protect your personal information. No method of
              transmission over the Internet, or method of electronic storage,
              is 100% secure. Therefore, while we strive to protect your
              personal information, we cannot guarantee its absolute security
              and any transmission of personal information is at your own risk.
            </p>

            <p className={classes.paragraph}>
              {" "}
              If a password is used to protect your account and personal
              information, it is your responsibility to keep your password
              confidential and to change it if you think it has been
              compromised.
            </p>

            <h3 className={classes.heading}>
              Your rights in relation to your personal information
            </h3>

            <p className={classes.paragraph}>
              Right to access: You have, subject to certain exceptions allowed
              for by law, the right to ask us to provide you with all of the
              personal information we store on you. If you would like to get
              access to, review or correct any of your personal information
              email us at{" "}
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
              If we have disclosed your personal information to a third party,
              in accordance with this Privacy Policy, we will take reasonable
              steps to notify the third party of the updated information.
            </p>
            <p className={classes.paragraph}>
              Obtaining a copy: We can provide you with a copy of personal
              information held on our current records at no charge. However, we
              will not be obliged to provide you with a copy or give you access
              to your personal information if by doing so we would reveal
              personal information about another person.
            </p>
            <p className={classes.paragraph}>
              Retention of your personal information: We will only keep your
              personal information for as long as is necessary for the purposes
              for which you provided it to us. However, we may keep your
              personal information for a longer period in order to comply with
              legal obligations.
            </p>
            <p className={classes.paragraph}>
              If the EU or UK GDPR applies: you are (among other things) also
              entitled to:
            </p>

            <ul>
              <li>
                {" "}
                receive transparent information regarding the processing of your
                personal information;
              </li>
              <li>
                {" "}
                access your personal information, including the right to obtain
                free of charge a copy of the personal information undergoing
                processing in a commonly available electronic format;
              </li>
              <li>
                {" "}
                rectification of incorrect personal information and completion
                of incomplete personal information;
              </li>
              <li>
                {" "}
                erasure of your personal information, including the “right to be
                forgotten”;
              </li>
              <li> restrict the processing of your personal information;</li>
              <li>
                {" "}
                your personal information being portable and accessible upon
                request;
              </li>
              <li>
                {" "}
                object to the processing of your personal information; and
              </li>
              <li>
                {" "}
                if you have consented to the processing of your personal
                information, you have the right to withdraw that consent.
              </li>
            </ul>
            <p className={classes.paragraph}>
              If you wish to exercise any of the above rights email us at
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
            </p>
            <p className={classes.paragraph}>
              Complaints: If you have questions, complaints or concerns
              regarding this Privacy Policy, please contact us by emailing us at{" "}
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
            </p>
            <p className={classes.paragraph}>
              We treat all complaints about a breach of the privacy laws
              applicable to you seriously. We will investigate your complaint
              and respond to you within a reasonable time. If you are not
              satisfied with the response, you have the right, depending on the
              jurisdiction, to make a complaint to the applicable regulator. In
              Australia, the applicable regulator is the Office of the
              Australian Information Commissioner. In Europe, the applicable
              regulator will be the local regulator in your jurisdiction in
              Europe and in the UK, it is the Information Commission’s Office.
            </p>
            <h3 className={classes.heading}>Marketing and External Linking</h3>
            <p className={classes.paragraph}>
              Opting in: We will not send you any type of marketing materials
              unless you have opted in to receive them.
            </p>
            <p className={classes.paragraph}>
              Direct marketing: Once you have opted in to receive marketing
              materials, we may send you surveys or marketing communications to
              inform you of new products or services or other information that
              may be of interest. Third parties with whom we have shared your
              personal information may send you direct marketing communications.
            </p>
            <p className={classes.paragraph}>
              Opting out: If you want to stop receiving direct marketing
              communications from either us or a third party, it is easy to opt
              out. You can send us an email request at info@wizer.life or press
              the “unsubscribe” button on the communication that is sent to you.
              Please keep in mind that, if you choose not to receive marketing
              communications, you will continue to receive transactional or
              account communications (e.g., confirmation emails and account
              balance statements). In the case of direct marketing
              communications from third parties, you may have to follow their
              directions on how to unsubscribe from their communications.
            </p>
            <p className={classes.paragraph}>
              Links: We may link to websites, including those of third-party
              content providers and social media sites. These websites may
              contain social media features such as Facebook, LinkedIn or
              Instagram buttons. These features may collect information about
              your device’s IP address, set cookies or link you to a social
              media website where you may post personal information. The
              websites of third party providers and social media sites may have
              different privacy policies and practices from those disclosed
              here. We assume no responsibility for the policies or practices of
              such websites and encourage you to become acquainted with them
              prior to use.
            </p>
            <h3 className={classes.heading}>Interactive Services</h3>
            <p className={classes.paragraph}>
              The Services are designed to allow users of the Services to
              interact with one another so that users can obtain the benefit of
              advice provided by a wise crowd. Additionally, in the creation of
              your wise crowd, we may ask for access to your contacts list in
              order to allow you to select any of your friends to invite to your
              crowd. Should you choose to invite these people, they will be
              contacted by us in relation to that request - which will be in the
              form of an unsolicited message. As mentioned above, we recommend
              you ensure the person you invite is aware of this and has your
              permission to invite them. .
            </p>
            <p className={classes.paragraph}>
              If you take part in a discussion, post a question or answer
              another user’s question, you need to be aware that anything you
              post is open to the public and any personal information you post
              or provide may be viewable by others. We are not responsible for
              personal information you submit in this context, nor are we
              responsible for how others might use that information, including
              sending you unsolicited messages. Your postings may be retained
              indefinitely. If at any time you would like to remove a posting,
              please email us at insert email{" "}
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
              Keep in mind that removal of a posting from the Wizer app or our
              website does not mean that the content will be deleted from our
              systems.
            </p>
            <h3 className={classes.heading}>Children</h3>
            <p className={classes.paragraph}>
              We do not intend to solicit or collect personal information from
              anyone under the age of 16. If you are under 16, you should not
              use or enter information on the Wizer app or our website,
              including without limitation, the asking or answering of any
              questions.
            </p>
            <h3 className={classes.heading}>Changes to This Privacy Policy</h3>
            <p className={classes.paragraph}>
              We reserve the right to change the terms of this Privacy Policy at
              any time. We encourage you to review this policy whenever you
              visit our website.
            </p>
            <h3 className={classes.heading}>Contact Information</h3>
            <p className={classes.paragraph}>
              You can contact us by emailing us at{" "}
              <a href="mailto:%20info@wizer.life" style={{ color: "white" }}>
                info@wizer.life&nbsp;
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyContent;
