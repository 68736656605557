import React from "react";
import cross from "../../assets/icons/Cross.svg";
import EnableValidateIcon from "../../assets/icons/CheckboxPurple.svg";
import { Col, FormControl} from "react-bootstrap";

const EmailInput = ({
  signUp,
  handleSignUpChange,
  showErrorMessage,
  emailExist,
}) => {
  return (
    <>
      <Col xs={12} className="d-flex align-items-center">
        <h4 className="text-left text-bold text-color label-text montserrat-font mr-3">
          Email
        </h4>
        <span className="email-icon">
          {signUp.email.length > 4 && showErrorMessage ? (
            <img src={cross}/>
          ) : (
            <img src={emailExist && EnableValidateIcon}  />
          )}
        </span>
      </Col>
      <Col xs={12}>
        <FormControl
          type="email"
          value={signUp.email}
          placeholder="Tap to enter your email!"
          onChange={(e) => {
            handleSignUpChange("email")(e);
          }}
          
          className="form-width no-shadow placeholder-text custom-highlight"
          style={{
            padding: "0 70px",
          }}
        />

        {signUp.email.length > 4 && showErrorMessage && (
          <div className="email-error-message">
            <p className="mb-10 mt-10 text-center">
              Oh no! That email is unavailable!
            </p>
          </div>
        )}
      </Col>
    </>
  );
};

export default EmailInput;
