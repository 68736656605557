import React from "react";
import classes from "./index.module.css";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Logo from "../../assets/icons/wizermainlogo.svg";
import AndriodBtn from "../../assets/images/google-play-badge.png";
import IosBtn from "../../assets/images/app-store-badge.svg";
import "./index.css";

export const MessageOnVoteAnnonymusUser = (props) => {
  return (
    <>
      <Modal {...props} centered scrollable autoFocus={false}>
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 0,
          }}
        >
          <div className={classes.heading_logo}>
            <img src={Logo} className={classes.logo} alt="Wizer Logo" />
          </div>
        </Modal.Header>

        <Modal.Body
          style={{
            borderBottom: "1px solid #7c10a7",
            borderTop: "1px solid #7c10a7",
            marginTop: 20,
          }}
        >
          <Container>
            <Row
              style={{
                margin: "auto",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Col xs={10} sm={12} md={12} lg={12} xl={12}>
                <p className={classes.dialogText}>
                  Want to continue sharing your expertise? Climb our leaderboard
                  to become an expert.
                </p>

                <div className={classes.button_container}>
                  <button
                    onClick={() => props.setType(2)}
                    className={classes.popUp_signUp_btn}
                  >
                    Sign Up
                  </button>
                  <button
                    onClick={() => props.setType(13)}
                    className={classes.popUp_login_btn}
                  >
                    Log In
                  </button>
                </div>

                <div className={classes.footer_title}>
                  Already a member? Continue growing your expertise and download
                  the Wizer app now!
                </div>
                <div className={classes.download_btn}>
                  <a href="https://play.google.com/store/apps/details?id=com.wizerapp">
                    <img
                      src={AndriodBtn}
                      className={classes.android_logo}
                      alt="Download on Google Play"
                    />
                  </a>
                  <a href="https://apps.apple.com/us/app/wizer/id1584085846">
                    <img
                      src={IosBtn}
                      className={classes.ios_logo}
                      alt="Download on the App Store"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
