import React from "react";
import classes from "./index.module.css";
import "../index.css";
import accept from "../../../assets/icons/AcceptImgClick.svg";
const MultipleImageWithTextQuestion = ({
  id,
  text,
  img,
  index,
  newShadow,
  visible,
  VisibleClickHandler,
  selectedOption,
  handleClick,
  notify,
}) => {
  const ClickHandler = () => {
    VisibleClickHandler(index, id);
    handleClick();
    notify();
  };

  return (
    <>
      <div onClick={ClickHandler} id={`img` + index} className={`img`}>
        <div
          className={
            "disable-img-overlay" &&
            (newShadow.length >= 1 || selectedOption !== id) &&
            !newShadow.includes(index) &&
            "enable-img-overlay"
          }
        >
          <img src={img} className={classes.bgImg} alt="accept" />
          {(visible.includes(index) || selectedOption == id) && (
            <img src={accept} className={classes.Clickimg} alt="accept" />
          )}
          <div className={classes.paraGraph}>{text}</div>
        </div>
      </div>
    </>
  );
};
MultipleImageWithTextQuestion.defaultProps = {
  img:
    "https://img.freepik.com/free-photo/red-yellow-watercolor_95678-448.jpg?t=st=1653637549~exp=1653638149~hmac=828f0c965fe36d5621006d40bbb7b925e5ef08da174757570c67d314ba2a6306&w=826",
};
export default MultipleImageWithTextQuestion;
