import React, { useState, useEffect } from "react";
import "./index.css";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
} from "react-bootstrap";
import UserIcon from "../../assets/icons/PickUserName.svg";
import EmailInput from "../../components/EmailInput";
import Password from "../../components/Password";
import BirthyearInput from "../../components/BirthyearInput";
import PickUserName from "../../components/PickUserName";
import PrivacyAndTermConditions from "../../components/PrivacyAndTermConditions";
import Footer from "../Footer";
import BackButton from "../../assets/icons/BackButton.svg";
import { checkCredential } from "../../api/Auth";
import { checkAge } from "../../utils/helpers";

const SinglePageSignUp = (props) => {
  const { signUp, setType, agree, accept } = props;
  const [validAge, setValidAge] = useState(true);
  const [emailExist, setEmailExist] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showAgeErrorMessage, setShowAgeErrorMessage] = useState(true);
  const [userExist, setUserExist] = useState(true);
  const [continueDisable, setContinueDisable] = useState(false);
  const [dobTyped, setDobTyped] = useState(false);

  const [passValidationCheck, setPassValidationCheck] = useState({
    uppercasePassword: false,
    lowercasePassword: false,
    digitsPassword: false,
    minLengthPassword: false,
    confirmPassMatch: false,
  });

  const continueButtonHandler = () => {


    
    setType(9);
  };

  useEffect(async () => {
    setUserExist(true);
    const checkAllValidationPassed = Object.values(passValidationCheck).every(
      (value) => value === true
    );
    setContinueDisable(!checkAllValidationPassed);
    // age verfied
    const verifyAge = checkAge(signUp.dob);
    setValidAge(verifyAge ? true : false);
    setShowAgeErrorMessage(verifyAge ? false : dobTyped);
    // email verfied
    if (signUp.email.length > 4) {
      checkCredential({ email: signUp.email }).then((result) => {
        setEmailExist(result.result);
        setShowErrorMessage(!result.result);
      });
    }
    //userexist verified
    if (signUp.username.length >= 4) {
      checkCredential({ username: signUp.username }).then((result) =>
        result.result ? setUserExist(false) : setUserExist(true)
      );
    } else {
      setUserExist(true);
    }
  }, [signUp.username, signUp.email, passValidationCheck, signUp.dob]);
  return (
    <>
      <Container
        className="align-items-center  mt-60 scrollbar"
        style={{ maxWidth: 615 }}
      >
        <Row>
          <Col xs={9} md={10}>
            <div>
              <button
                className="back-button"
                type="button"
                onClick={() => {
                  setType(2);
                }}
              >
                <img src={BackButton} className="btn-main" alt="back button" />
              </button>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <Image src={UserIcon} className="mx-auto" alt="User Icon" />
          </Col>
          <Col xs={12} className="d-flex align-items-center mb-4 mt-4">
            <h4 className="m-auto text-center text-bold text-color montserrat-font">
              Please fill out some details about yourself to sign up!
            </h4>
          </Col>
        </Row>
        {/* email */}
        <Row className="m-auto d-flex align-items-center mb-3 mt-3">
          <EmailInput
            {...props}
            showErrorMessage={showErrorMessage}
            // handleContinue={handleContinue}
            emailExist={emailExist}
          />
        </Row>

        {/* password */}
        <Password
          {...props}
          setPassValidationCheck={setPassValidationCheck}
          passValidationCheck={passValidationCheck}
        />
        {/* Birthyear */}
        <Row className="m-auto d-flex align-items-center text-color ">
          <BirthyearInput
            {...props}
            // handleSubmit={handleSubmit}
            setDobTyped={setDobTyped}
            showAgeErrorMessage={showAgeErrorMessage}
            validAge={validAge}
          />
        </Row>

        <Row className=" m-auto d-flex align-items-center">
          <PickUserName {...props} userExist={userExist} />
        </Row>
        <Row className=" m-auto  d-flex align-items-center">
          <PrivacyAndTermConditions {...props} />
        </Row>
      </Container>
      <Row className=" m-auto  d-flex align-items-center">
        <Col xs={12}>
          <Button
            type="submit"
            disabled={
              !emailExist ||
              continueDisable ||
              !validAge ||
              userExist ||
              !agree ||
              !accept
            }
            onClick={continueButtonHandler}
            className="btn-continue"
          >
            Continue
          </Button>
        </Col>
      </Row>

      <Footer />
    </>
  );
};

export default SinglePageSignUp;
