import { Col, Row } from "react-bootstrap";
import classes from "./index.module.css";
import React from "react";
const EmailSignUp = ({setType}) => {
  return (
    <div>
        <Row>
          <Col>
            <div className={classes.email_sign_up}>
              <button
                type=" button"
                onClick={() => {
                  setType(3);
                }}
                className={classes.btn}
              >
                <p> Use my email</p>
              </button>
            </div>
          </Col>
        </Row>
    </div>
  );
};
export default EmailSignUp;
