import React from "react";
import classes from "./index.module.css";
import ListCategories from "../../ListCategories";
const LogoIcon = (props) => {
  const { signUp, userCategories, setUserCategories } = props;
  return (
    <div>
      <div className={classes.logo_icon}>
        <ListCategories
          userCategories={userCategories}
          setUserCategories={setUserCategories}
          signUp={signUp}
        />
      </div>
    </div>
  );
};
export default LogoIcon;
