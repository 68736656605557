import classes from "./index.module.css";
import React, { useState } from "react";
import BackButton from "../../assets/icons/BackButton.svg";
import Calender from "../../assets/icons/Calender.svg";
import { Col,  Row } from "react-bootstrap";
import ErrorMessage from "./ErrorMessage";
import { checkAge } from "../../utils/helpers";
const SignUpBirthday = (props) => {
  const { signUp, setType, handleSignUpChange } = props;
  const [validAge, setValidAge] = useState(true);
  const handleSubmit = () => {
    const verifyAge = checkAge(signUp.dob);
    if (verifyAge) {
      setValidAge(true);
      setType(6);
    } else {
      setValidAge(false);
    }
  };

  return (
      <>
        <Row>
          <Col lg={7}>
            <div className={classes.SignUp_Birthday_Screen}>
              <Row>
                <Col xs={9} md={10}>
                  <div className={classes.back_button}>
                    <button
                      type="button"
                      onClick={() => {
                        setType(4);
                      }}
                      className={classes.btn}
                    >
                      <img src={BackButton} alt="back button" />
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={classes.SignUpSection}>
                    <img
                      src={Calender}
                      alt="date of birth"
                      className={classes.Calender}
                    />
                    <p>What year were you born?</p>
                    <span>This won’t be shown publicly.</span>
                    <div className={classes.input_field}>
                      <div className={classes.username}>
                        <input
                          type="text"
                          pattern="\d*"
                          maxLength="4"
                          value={signUp.dob}
                          placeholder="Tap to enter your year!"
                          onChange={handleSignUpChange("dob")}
                          className={classes.input_placeholder}
                        />
                        {!validAge && <ErrorMessage />}
                      </div>{" "}
                    </div>{" "}
                  </div>
                 
                </Col>
              </Row>
             
                  <div className={classes.continue_button}>
                    <button
                      type="submit"
                      disabled={!signUp.dob }
                      onClick={() => handleSubmit()}
                      className={classes.button_continue}
                    >
                      <p>Continue</p>
                    </button>
                  </div>
               
            </div>
          </Col>
        </Row>
      </>
  );
};
export default SignUpBirthday;
