import React from "react";
import classes from "./index.module.css";
import { Col, Row } from "react-bootstrap";
const LogInMe = ({setType}) => {
  return (
      <Row>
        <Col>
          <div className={classes.sign_in}>
            <button
              type=" button"
              onClick={() => {
                  setType(13);
              }}
              className={classes.btn}
            >
              <p> LOG ME IN!</p>
            </button>
          </div>
        </Col>
      </Row>
  );
};
export default LogInMe;
