import React, { useState, useEffect } from "react";
import hide from "../../assets/icons/pswdeye.svg";
import show from "../../assets/icons/showpswdeye.svg";
import { Row, Col, FormControl, Image } from "react-bootstrap";
import ValidationPassword from "../../Screens/SignUpPassword/ValidationPassword";

const Password = ({
  signUp,
  handleSignUpChange,
  passValidationCheck,
  setPassValidationCheck,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    // for password
    let passValidationValue = { ...passValidationCheck };
    if (passwordInputFieldName == "confirmPassword") {
      passValidationValue["confirmPassMatch"] =
        passwordInputValue === signUp.password ? true : false;
      setPassValidationCheck(passValidationValue);
    } else {
      Object.keys(passValidationCheck).map((key, value) => {
        if (key !== "confirmPassMatch") {
          let regexVal =
            key == "uppercasePassword"
              ? /(?=.*?[A-Z])/
              : key == "lowercasePassword"
              ? /(?=.*?[a-z])/
              : key == "digitsPassword"
              ? /(?=.*?[0-9])/
              : key == "minLengthPassword"
              ? /.{8,}/
              : "";
          passValidationValue[key] = regexVal.test(passwordInputValue);
        }
      });
      setPassValidationCheck(passValidationValue);
    }
  };
  return (
    <>
      <Row className="m-auto d-flex align-items-center mb-3 mt-3">
        <Col xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 className="text-left text-bold text-color label-text montserrat-font">
              Password
            </h4>
            <span>
              <Image
                src={showPassword ? show : hide}
                onClick={() => setShowPassword((prevState) => !prevState)}
                className="show-password-icon"
              />
            </span>
          </div>
        </Col>
        <Col xs={12}>
          <FormControl
            className="form-width no-shadow placeholder-text custom-highlight"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={signUp.password}
            onChange={handleSignUpChange("password")}
            onKeyUp={handleValidation}
            style={{
            padding: "0 70px",
          }}
          />
        </Col>
      </Row>

      {/* Confirm password */}

      <Row className="m-auto d-flex align-items-center mb-3 mt-3">
        <Col xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 className="text-left text-bold text-color label-text montserrat-font">
              Confirm Password
            </h4>

            <span>
              <Image
                src={showConfirmPassword ? show : hide}
                onClick={() =>
                  setShowConfirmPassword((prevState) => !prevState)
                }
                className="show-confirm-password-icon"
              />
            </span>
          </div>
        </Col>
        <Col xs={12}>
          <FormControl
            className="form-width  no-shadow placeholder-text custom-highlight"
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            value={signUp.confirmPassword}
            onChange={handleSignUpChange("confirmPassword")}
            onKeyUp={handleValidation}
            style={{
              padding: "0 70px",
          }}
          />
        </Col>

        <ValidationPassword
          signUp={signUp}
          handleSignUpChange={handleSignUpChange}
          passValidationCheck={passValidationCheck}
        />
      </Row>
    </>
  );
};

export default Password;
