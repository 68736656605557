import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./index.module.css";
import BackButton from "../../assets/icons/white.svg";
const TermAndConditionContent = ({ setType }) => {

  return (
    <Container className={classes.bg}>
      <Row>
        <Col md={7}>
          <div className={classes.Modal_Screen}>
            <Row>
              <Col xs={9} md={12}>
                <div className={classes.back_button}>
                  <button
                    type="button"
                    onClick={() => {
                      setType(3);
                    }}
                    style={{
                      backgroundColor: "transparent",
                      border: "none"
                    }}
                  >
                    <img src={BackButton} alt="back button" />
                  </button>
                </div>
                <br />
               
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={classes.privacy}>
            <h3 className={classes.heading}>WIZER – THE BORING BITS</h3>
            <h2 className={classes.heading}>(TERMS AND CONDITIONS)</h2>
            <p className={classes.paragraph}>
              <b>
                Note- Assumptions are that Greenlight Technology is the owner
                and operator of Wizer, the platform.
              </b>
            </p>
            <p className={classes.paragraph}>
              Terms and Conditions may be dull, but they’re important. We’ve
              tried to make ours as plain English as possible, and have set out
              to do three things:
            </p>
            <p className={classes.paragraph}>
              1. Ensure Wizer is used properly by people trying to help other
              people to make better decisions, rather than by people and
              organisation just trying to sell stuff;
            </p>{" "}
            <p className={classes.paragraph}>
              2. Make sure no-one publishes inappropriate or offensive material
              on Wizer;
            </p>
            <p className={classes.paragraph}>
              3 .To protect your personal information and online security.
            </p>
            <p className={classes.paragraph}>
              So, please read on, and if there’s anything you want more info on,
              our contact details are listed at the end. Enjoy (unlikely!).
            </p>
            <h3 className={classes.heading}>Wizer’s Mission</h3>
            <p className={classes.paragraph}>
              Our mission is to help people make better decisions by using the
              wisdom of the crowd.
            </p>
            <p>
              We will create a personal experience that suggests people you may
              like to add to your wise crowd. Our suggestions will ensure you
              have a diverse, experienced group of wizerds to guide your
              decisions.
            </p>
            <h3 className={classes.heading}>Overview</h3>
            <p className={classes.paragraph}>
              These terms and conditions (the "Terms and Conditions") govern the
              use of Wizer (the "Platform"). This Platform is owned and operated
              by Greenlight Ventures.
            </p>
            <p className={classes.paragraph}>
              By registering, accessing or using the Platform, you agree that
              you are 13 years old or over, and that you have read and
              understand these Terms and that you agree to comply with them.
            </p>
            <h3 className={classes.heading}>Intellectual Property</h3>
            <p className={classes.paragraph}>
              We respect intellectual property rights and we ask that you do the
              same. As a condition of your access to and use of the Services,
              you agree to terms of the Copyright Policy.
            </p>
            <p className={classes.paragraph}>
              Copyright is a legal right that protects original works of
              authorship. General, copyright protects an original expression of
              an idea, but does not protect underlying ideas or facts. Wizer
              does not allow any content that infringes copyright. The use of
              copyrighted content that belongs to others without proper
              authorization or a legally valid reason may lead to a violation of
              the platform’s policy.
            </p>
            <p>
              {" "}
              For avoidance of doubt, you should only post content that you have
              created yourself (such as photos and videos). As a user, you are
              responsible for the content you post. If you have questions about
              copyright law, such as questions about whether the content your
              post infringes on the copyright of another person or entity, you
              may wish to seek legal advice.
            </p>
            <h3 className={classes.heading}>Accounts</h3>
            <p className={classes.paragraph}>
              When you create an account on the Platform, you agree to the
              following:
            </p>
            <p className={classes.paragraph}>
              1. You are solely responsible for your account and the security
              and privacy of your account, including passwords or sensitive
              information attached to that account; and
            </p>
            <p>
              2. All personal information you provide to us through your account
              is accurate and truthful.
            </p>
            <p>
              We reserve the right to suspend or terminate your account if you
              are using our Platform illegally or if you violate these Terms and
              Conditions.
            </p>
            <h3 className={classes.heading}>Third Party Opinions</h3>
            <p className={classes.paragraph}>
              Our Platform offers opinions about matters you put forward from
              other parties. We cannot guarantee the quality or accuracy of
              opinions made available by other parties on our Platform. Users
              are encouraged to use Wizer as a tool for assisting with decision
              making. Any recommendations from the Platform are not binding.
            </p>
            <h3 className={classes.heading}>Limitation of Liability</h3>
            <p className={classes.paragraph}>
              Wizer and our directors, officers, agents, employees,
              subsidiaries, and affiliates will not be liable for any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees from your use of the Platform.
            </p>
            <h3 className={classes.heading}>Indemnity</h3>
            <p className={classes.paragraph}>
              Except where prohibited by law, by using this Platform you
              indemnify and hold harmless Wizer and our directors, officers,
              agents, employees, subsidiaries, and affiliates from any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees arising out of your use of our Site or your violation of
              these Terms and Conditions.
            </p>
            <h3 className={classes.heading}>Applicable Law</h3>
            <p className={classes.paragraph}>
              These Terms and Conditions are governed by the laws of the State
              of New South Wales, Australia.
            </p>
            <h3 className={classes.heading}>Severability</h3>
            <p className={classes.paragraph}>
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid.
            </p>
            <h3 className={classes.heading}>Data</h3>
            <p className={classes.paragraph}>
              We respect your data. Your personal information and your data will
              not be shared or sold by the Platform with any third party, except
              in the following cases: 1. If the law requires it; 2. If it is
              required for any legal proceeding; 3. To prove or protect our
              legal rights; and 4. To buyers or potential buyers of this company
              in the event that we seek to sell the company.
            </p>
            <h3 className={classes.heading}>Offensive Material</h3>
            <p className={classes.paragraph}>
              Your access to and use of the Platform is subject to these Terms
              and all applicable laws and regulations. You may not:
            </p>
            <p className={classes.paragraph}>
              <ul>
                <li>
                  access or use the Services if you are not fully able and
                  legally competent to agree to these Terms;
                </li>
                <li>
                  carry out any activity during the access or use of our
                  Platform which is unlawful, misleading, discriminatory or
                  fraudulent in any way.
                </li>
                <li>
                  make unauthorised copies, modify, adapt, translate, reverse
                  engineer, disassemble, decompile or create any derivative
                  works of the Platform or any content included therein,
                  including any files, tables or documentation (or any portion
                  thereof) or determine or attempt to determine any source code,
                  algorithms, methods or techniques embodied by the Platform or
                  any derivative works thereof;
                </li>
                <li>
                  use the Platform, without our express written consent, for any
                  commercial or unauthorized purpose, including communicating or
                  facilitating any commercial advertisement or solicitation or
                  spamming;
                </li>
                <li>
                  use the Platform, without our express written consent, for any
                  commercial or unauthorized purpose, including communicating or
                  facilitating any commercial advertisement or solicitation or
                  spamming;
                </li>
                <li>
                  use the Platform, without our express written consent, for any
                  commercial or unauthorized purpose, including communicating or
                  facilitating any commercial advertisement or solicitation or
                  spamming;
                </li>
                <li>
                  use or attempt to use another’s account, service or system
                  without authorisation from Wizer, or create a false identity
                  on the Platform;
                </li>
                <li>
                  use the Platform in a manner that may create a conflict of
                  interest or undermine the purposes of the Platform, such as
                  trading reviews with other users or writing or soliciting fake
                  reviews;
                </li>
                <li>
                  use the Platform to upload, transmit, distribute, store or
                  otherwise make available in any way:
                </li>
              </ul>
            </p>
            <p className={classes.paragraph}>
              files that contain viruses, trojans, worms, logic bombs or other
              material that is malicious or technologically harmful;
            </p>
            <p className={classes.paragraph}>
              any unsolicited or unauthorised advertising, solicitations,
              promotional materials, “junk mail,” “spam,” “chain letters,”
              “pyramid schemes,” or any other prohibited form of solicitation;
            </p>
            <p className={classes.paragraph}>
              any private information of any third party, including addresses,
              phone numbers, email addresses, number and feature in the personal
              identity document (e.g., Drivers Licence, passport numbers) or
              credit card numbers;
            </p>
            <p className={classes.paragraph}>
              any private information of any third party, including addresses,
              phone numbers, email addresses, number and feature in the personal
              identity document (e.g., Drivers Licence, passport numbers) or
              credit card numbers;
            </p>
            <p className={classes.paragraph}>
              any material which is defamatory of any person, obscene,
              offensive, pornographic, hateful or inflammatory;
            </p>
            <p className={classes.paragraph}>
              any material that would constitute, encourage or provide
              instructions for a criminal offence, dangerous activities or
              self-harm;
            </p>
            <p className={classes.paragraph}>
              any material that is deliberately designed to provoke or
              antagonise people, especially trolling and bullying, or is
              intended to harass, harm, hurt, scare, distress, embarrass or
              upset people;
            </p>
            <p className={classes.paragraph}>
              any material that is racist or discriminatory, including
              discrimination on the basis of someone’s race, religion, age,
              gender, disability or sexuality;
            </p>
            <p className={classes.paragraph}>
              any answers, responses, comments, opinions, analysis or
              recommendations that you are not properly licensed or otherwise
              qualified to provide; or
            </p>
            <p className={classes.paragraph}>
              material that, in the sole judgment of Wizer, is objectionable or
              which restricts or inhibits any other person from using the
              Platform, or which may expose Wizer, the Platform or its users to
              any harm or liability of any type.
            </p>
            <h3 className={classes.heading}>Content</h3>
            <p className={classes.paragraph}>
              Content you upload to Wizer will be considered non-confidential
              and non-proprietary. You must not post any Content on or through
              the Platform or transmit to us any Content that you consider to be
              commercially confidential or proprietary. When you submit Content
              through the Platform, you agree and represent that you own that
              Content, or you have received all necessary permissions,
              clearances from, or are authorised by, the owner of any part of
              the content to submit it to the Platform, to transmit it from the
              Platform to other Wizer users.
            </p>
            <h3 className={classes.heading}>Termination</h3>
            <p className={classes.paragraph}>
              We reserve the right to temporarily or permanently suspend or
              terminate your user account for reasons including:
              <ul>
                <li>
                  If we reasonably believe you violate, or we have objective
                  grounds to reasonably believe you are about the violate, the
                  Terms, policies or guidelines, or any applicable laws or
                  regulations;
                </li>
                <li>
                  If activities occur on your account which, in our sole
                  discretion, would or might cause damage to or impair our
                  Platform, or infringe or violate any third-party rights
                  (including intellectual property rights)
                </li>
                <li>
                  If activities occur on your account which, in our sole
                  discretion, would or might cause damage to or impair our
                  Platform, or infringe or violate any third-party rights
                  (including intellectual property rights)
                </li>
                <li>
                  Due to unexpected technical or security issues or problems
                </li>
                <li>
                  If there are extended periods of inactivity on your account
                </li>
                <li>
                  If we permanently suspend or terminate your user account, we
                  will notify you in advance in order to allow you time to
                  access and save your information.
                </li>
              </ul>
            </p>
            <h3 className={classes.heading}>Changes</h3>
            <p className={classes.paragraph}>
              These Terms and Conditions may be amended from time to time in
              order to maintain compliance with the law and to reflect any
              changes to the way we operate our Platform and the way we expect
              users to behave on our Platform. We will notify users by email of
              changes to these Terms and Conditions or post a notice on our
              Platform.
            </p>
            <p className={classes.paragraph}>
              This policy is effective as of 7th November 2021
            </p>
            <h3 className={classes.heading}>Contact Details</h3>
            <p className={classes.paragraph}>
              Please contact us if you have any questions or concerns. Our
              contact details are as follows:
            </p>
            <p className={classes.paragraph}>
              Community Manager, Wizer Email: community@wizer.life
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TermAndConditionContent;
