import React from "react";
import Error404 from "../../Screens/404";
import axios from "../../services/interceptor";

export const getDomainDtl = async (params) => {
  const res = await axios.get(`/organization/get/domain`, {
    params: { ...params },
  });

  if (res && !res.data.error) {
    localStorage.setItem("organization_id", res.data.organization_id);
  }
 
  return res.data;
};

export const getOrganizationGlobalPost = async () => {
  let res;
  const userId = localStorage.getItem("userId");
  const orgid = localStorage.getItem("organization_id");

  let apiUrl = `/organization/get/organization/posts?limit=25&offset=1&id=${orgid}&role=admin&closed=false&visibility=public`;
  let apiUrlforwizer = `/organization/get/organization/posts?limit=25&offset=1&closed=false&visibility=public`

  if (userId) {
    apiUrl += `&viewerid=${userId}`;
  }
  res = await axios.get(apiUrl); 
  
  var parenturl =  window.location != window.parent.location  ? document.referrer   : document.location.href;

    const domainList= new Map([
      ["https://wizer.life/", "1"], ["https://www.business.wizer-staging.life/", "1"],  ["https://wizer.wizer-staging.life/", "1"], 
    ]);

  let getallposts = domainList.get(parenturl) || "0";

  if (res.data.length === 0 && getallposts == "1") {
    if (userId) {
      apiUrlforwizer += `&viewerid=${userId}`;
    }
    res = await axios.get(
      apiUrlforwizer
    );
  } 
  if (res.data.length === 0 ) {
    res = await axios.get(
      `/organization/get/organization/posts?limit=5&offset=1&id=${orgid}&role=admin&closed=true&visibility=public`
    );
  } 

  return res.data;
};
export const getOrganizationPost = async (params) => {
  let res;
  const userId = localStorage.getItem("userId");
  const orgid = localStorage.getItem("organization_id");
  
  let apiUrl = `/organization/get/organization/withoutTag-posts?limit=25&offset=1&organizationId=${orgid}&role=admin&closed=false&visibility=public`;

  if (userId) {
    apiUrl += `&viewerid=${userId}`;
  }

  res = await axios.get(apiUrl);

  return res.data;
};

export const getOrganizationPostwithTag = async (params) => {
  const orgid = localStorage.getItem("userId");
  const res = await axios.get(`/organization/get/organization/tag-posts`, {
    params: { ...params },
  });

  return res.data;
};

export const cancelSubscription = async (params) => {
  const res = await axios.patch(`/organization/cancel/subscription`, {
    params: { ...params },
  });
  return res.data;
};
export const removeOrganizationMember = async (params) => {
  const res = await axios.patch(`/organization/remove/organization-member`, {
    params: { ...params },
  });
  return res.data;
};
export const editAIquestion = async (payload) => {
  const res = await axios.patch(`/organization/edit/AI_question`, payload);
  return res.data;
};

export const getAvailableSubscription = async (params) => {
  const res = await axios.get(`/organization/get/available-subscription`, {
    params: { ...params },
  });
  return res.data;
};
export const getSubscribedSubscription = async (params) => {
  const res = await axios.get(
    `/organization/get/subscription-subscribed/byuser`,
    { params: { ...params } }
  );
  return res.data;
};
export const getAIQuestionByID = async (params) => {
  const res = await axios.get(`/organization/get/AI-question/byId`, {
    params: { ...params },
  });
  return res.data;
};
export const getOrganizationDtl = async (params) => {
  const res = await axios.get(`/organization/get/organization`, {
    params: { ...params },
  });
  return res.data;
};
export const getAllOrganizationMember = async (params) => {
  const res = await axios.get(`/organization/get/all/organization-member`, {
    params: { ...params },
  });
  return res.data;
};

export const createOrganizationMember = async (payload) => {
  const res = await axios.post(
    `/organization/create/organization-member`,
    payload
  );
  return res.data;
};

export const createSubscriptionOptions = async (payload) => {
  const res = await axios.post(
    `/organization/create/subscription-option`,
    payload
  );
  return res.data;
};

export const saveUserSubscription = async (payload) => {
  const res = await axios.post(`/organization/subcribe/subscription`, payload);
  return res.data;
};

export const storeAIQuestion = async (payload) => {
  const res = await axios.post(`/organization/store/AI-question`, payload);
  return res.data;
};

export const deleteAIQuestionByID = async (params) => {
  const res = await axios.delete(`/organization/delete/AI-question/byId`, {
    params: { ...params },
  });
  return res.data;
};

export const createHTML = async (payload) => {
  const res = await axios.post(`/organization/create/html`, payload);
  return res.data;
};

export const updateAITagQuestion = async (payload) => {
  const res = await axios.patch(
    `/organization/update/AI_question/Tag`,
    payload
  );
  return res.data;
};
