import { useState, useEffect } from "react";
import { anonymousStatusCheck } from "../api/Anonymous";
import { getPortalTypeAccordingToUrl } from "../utils/helpers";

export const useAnonymousStatus = () => {
  const [anonymousStatus, setAnonymousStatus] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const name = await getPortalTypeAccordingToUrl();
        const res = await anonymousStatusCheck({ portal: name });
        if (res && res.length > 0 && res[0].anonymousStatus != null) {
          setAnonymousStatus(res[0].anonymousStatus);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return anonymousStatus;
};