import React from "react";
import classes from "./index.module.css";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BiTimer } from "react-icons/bi";

export const PostCloseTime = (props) => {
  return (
    <Row>
      <Col xxl={6} md={7}>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className={classes.modal_Text}>
            <Modal.Header
              closeButton
              style={{ borderBottom: "none" }}
            ></Modal.Header>
            <Modal.Body>
              <p className={classes.time_background}>
                Come back in{" "}
                <div>
                  {" "}
                  <BiTimer
                    style={{ height: "50px", width: "50px", color: "#7c10a7" }}
                  />
                </div>
                <div className={classes.remain_time}>
                  {props.postremainingtime && props.postremainingtime.days > 0
                    ? props.postremainingtime.days + "d "
                    : null}
                  {props.postremainingtime && props.postremainingtime.hours > 0
                    ? props.postremainingtime.hours + "h "
                    : null}
                  {props.postremainingtime &&
                  props.postremainingtime.minutes > 0
                    ? props.postremainingtime.minutes + "m "
                    : null}
                </div>{" "}
                to see the results{" "}
              </p>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "center",
                borderTop: "none",
              }}
            ></Modal.Footer>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

