import { useEffect, useState } from "react";

export const getParentUrl = () => {
  const [parentUrl, setParentUrl] = useState("");

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "PARENT_URL") {
        setParentUrl(event.data.url);
      }
    };

    const requestParentUrl = () => {
      window.parent.postMessage({ type: "REQUEST_PARENT_URL" }, "*");
    };

    window.addEventListener("message", handleMessage);

    try {
      requestParentUrl();
    } catch (error) {
      setParentUrl(document.referrer || "");
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return parentUrl;
};
