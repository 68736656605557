import React, { useState } from "react";
import classes from "./index.module.css";
import { Col, Row } from "react-bootstrap";
import Comment from "../../../assets/icons/Comments.svg";
import Vote from "../../../assets/icons/Vote.svg";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className={classes.text}>
      {isReadMore && text ? text.slice(0, 40) : text}
      <span onClick={toggleReadMore} className={classes.read_or_hide}>
        {isReadMore ? "...more" : " ...less"}
      </span>
    </div>
  );
};

const QuestionCommentAndVote = ({
  specificList,
  questionData,
  handleCommentClick,
  footercss
}) => {
  const descriptionValue = questionData.post.description;

  return (
    <div>
      <Row style={footercss && footercss.footer}>
        <Col xs={9} md={10}>
          <div className={classes.question_heading_content} >
            <div className={classes.heading} style={footercss && footercss.font}> {questionData.post.question}</div>
          </div>{" "}
          <div>
            {specificList.map((category, id) => {
              return (
                <div key={id} className={classes.questionCategory}>
                  <span> {category.category.icon}</span>
                  <span style={footercss && footercss.font}> #{category.category.title}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.text}>
            <>
              {descriptionValue && descriptionValue.length > 60 ? (
                <ReadMore style={footercss && footercss.font}>{questionData.post.description}</ReadMore>
              ) : (
                <p className={classes.text}> {questionData.post.description}</p>
              )}
            </>
          </div>
        </Col>
        <Col xs={3} md={2}>
          <div className={classes.count_section}>
            <div className={classes.comment_and_vote}>
              <img style={footercss && footercss.footericon}
                src={Comment}
                alt="comment"
                className={classes.comment}
                onClick={handleCommentClick}
              />
              <div className={classes.comments} style={footercss && footercss.font}>
                {questionData.post.comments}
              </div>
            </div>
            <div className={classes.count}>
              <img src={Vote} alt="vote" style={footercss && footercss.footericon} className={classes.vote} />

              <div className={classes.votes} style={footercss && footercss.font}>{questionData.post.votes}</div>
            </div>{" "}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default QuestionCommentAndVote;
