import classes from "./index.module.css";
import React from "react";
import backButton from "../../assets/icons/BackButton.svg";
import UserName from "../../assets/icons/PickUserName.svg";
import { Col, Row } from "react-bootstrap";
import EmailSignIn from "./EmailSignIn";
import GoogleSignUp from "../SignUp/GoogleSignUp";
const SignIn = (props) => {
  const { setType } = props;
  return (
      <>
        <Row>
          <Col lg={7}>
            <div className={classes.sign_up}>
              <div className={classes.back_button}>
                <button
                  type="button"
                  onClick={() => {
                    setType(1);
                  }}
                  className={classes.btn}
                >
                  <img src={backButton} alt="back button" />
                </button>
              </div>

              <div className={classes.sign_up_options}>
                <p className={classes.sign_up_hint}>
                  You need to sign up before you can share your opinion!
                </p>
                <img
                  src={UserName}
                  alt="back button"
                  className={classes.union_svg}
                />
                <p className={classes.sign_up_hint_text}>
                  How do you want to sign in?
                </p>
                <EmailSignIn setType={setType} />
                <div className={classes.google}>
                  {" "}
                  <GoogleSignUp  setType={setType} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
  );
};
export default SignIn;
