import React, { useEffect, useState } from "react";
import classes from "./index.module.css";
import BackButton from "../../assets/icons/BackButton.svg";
import Email from "../../assets/icons/mail.svg";
import { Col, Row } from "react-bootstrap";
import { signIn } from "../../api/Auth";
import { postAnonymousDataToTable } from "../../api/Anonymous";
const Login = ({ setType }) => {
  const [continueDisable, setContinueDisable] = useState(true);
  const [signInVal, setSignInVal] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (signInVal.email.length > 1 && signInVal.password.length) {
      setContinueDisable(false);
    }
  }, [signInVal]);

  const handleSignInChange = (type) => (e) => {
    let newSignInDetail = { ...signInVal };
    newSignInDetail[type] = e.target.value;
    setSignInVal(newSignInDetail);
  };

  const handleSubmit = async () => {
    let response = await signIn({
      email: signInVal.email,
      password: signInVal.password,
    });

    // If email not found, check for email with first letter capitalized
    if (response && response.error && response.error === "User not found") {
      let capitalizedEmail =
        signInVal.email.charAt(0).toUpperCase() + signInVal.email.slice(1);
      response = await signIn({
        email: capitalizedEmail,
        password: signInVal.password,
      });
    }

    // If email still not found, check for lower case email
    if (response && response.error && response.error === "User not found") {
      let lowerCaseEmail = signInVal.email.toLowerCase();
      response = await signIn({
        email: lowerCaseEmail,
        password: signInVal.password,
      });
    }

    if (response && response.tokens && response.tokens.accessToken !== "") {
      setType(10);
    
    }
  };
  return (
    <>
      <Row>
        <Col lg={7}>
          <div className={classes.login}>
            {" "}
            <div className={classes.back_button}>
              <button
                type="button"
                onClick={() => {
                  setType(13);
                }}
                className={classes.btn}
              >
                <img src={BackButton} alt="back button" />
              </button>
            </div>
            <div className={classes.login_section}>
              <img src={Email} alt="email" className={classes.Email} />
              <p>Fab! Enter your login details below!</p>
            </div>
            <div className={classes.InputField}>
              <input
                type="text"
                name="email"
                placeholder="Tap to enter your email"
                className={classes.mail_field}
                onChange={handleSignInChange("email")}
                value={signInVal.username}
              />
              <input
                type="password"
                name="password"
                placeholder="Tap to enter your password"
                className={classes.password_field}
                onChange={handleSignInChange("password")}
                value={signInVal.password}
              />
            </div>
            <div className={classes.continue_button}>
              <button
                type="submit"
                disabled={continueDisable}
                className={classes.button_continue}
                onClick={() => handleSubmit()}
              >
                <p>Continue</p>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Login;
