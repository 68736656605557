import React from "react";
import { Col, FormControl } from "react-bootstrap";
import cross from "../../assets/icons/Cross.svg";
import EnableValidateIcon from "../../assets/icons/CheckboxPurple.svg";
const PickUserName = ({ signUp, setType, handleSignUpChange, userExist }) => {
  return (
    <>
      <Col xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className=" text-left text-bold text-color label-text montserrat-font">
            Pick a username
          </h4>
          <span className="username-valid-icon">
            {signUp.username.length >= 4 && userExist ? (
              <img src={cross}/>
            ) : (
              <img src={!userExist && EnableValidateIcon} />
            )}
          </span>
        </div>
      </Col>
      <Col xs={12}>
        <FormControl
          className="form-width  no-shadow placeholder-text custom-highlight"
          value={signUp.username}
          placeholder="user name"
          onChange={handleSignUpChange("username")}
          style={{
            padding: "0 70px",
          }}
        />

        {signUp.username.length >= 4 && userExist ? (
          <div>
            <div className="username-error-message">
              <p className="mb-10 mt-10 text-center">
                Oh no! That username is unavailable!
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </Col>
    </>
  );
};

export default PickUserName;
