import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';

const Error404 = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row>
        <Col className="text-center">
          <BsExclamationTriangle  color= "#7c10a7" size={80} />
          <h1>404</h1>
          <h3 >Page not found</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Error404;
