import React  from "react";
import classes from "./index.module.css";
import BackButton from "../../assets/icons/BackButton.svg";
import {  Row, Col } from "react-bootstrap";
import {
  arrayToObject,
  getPortalTypeAccordingToUrl,
} from "../../utils/helpers";
import { userSignUp } from "../../api/Auth";
import { createOrganizationMember } from "../../api/Organization";
import LogoIcon from "./LogoIcon";
import { addCategories } from "../../api/Categories";
import { signIn } from "../../api/Auth";

const Cateogory = (props) => {
  const { signUp, setType, userCategories, setUserCategories } = props;

  const handleClick = async () => {
    let objectOfCategories = { categories: arrayToObject(userCategories) };
    
    // const portalName = await getPortalTypeAccordingToUrl();
    await userSignUp({
      username: signUp.username,
      email: signUp.email,
      password: signUp.password,
      birthday: signUp.dob,
      portalType: "wizer",
    });
    await signIn({ email: signUp.email, password: signUp.password });

   const orgId =  localStorage.getItem("organization_id");
   debugger;
   if(orgId)
   {
    await  createOrganizationMember({role: "user", organizationId: parseInt(orgId)});
   }

    addCategories(objectOfCategories);
    setType(10);
  };

  return (
    <>
      <Row>
        <Col lg={7}>
          <div className={classes.cateogory_screen}>
            <Row>
              <Col xs={9} md={10}>
                <div className={classes.back_button}>
                  <button
                    type="button"
                    onClick={() => {
                      setType(3);
                    }}
                    className={classes.btn}
                  >
                    <img src={BackButton} alt="back button" />
                  </button>
                </div>
              </Col>
            </Row>
            <div className={classes.Category_list}>
              <p className={classes.cateogory_screen_content}>
                What are you an expert at?
              </p>
              <p className={classes.cateogory_screen_title}>Pick 3 or more!</p>
            </div>
            <LogoIcon
              signUp={signUp}
              userCategories={userCategories}
              setUserCategories={setUserCategories}
            />

            <div className={classes.next_button}>
              <button
                type="button"
                onClick={handleClick}
                className={classes.next_continue}
              >
                <p>Next</p>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Cateogory;
