import { Col, Row } from "react-bootstrap";
import React from "react";
import "./index.css";
const AcceptTermsAndCondition = (props) => {
  const { setAccept, accept } = props;

  return (
    <>
      <Row>
        <Col lg={6}>
          <div className="terms_and_condition_sub_title">
            <input
              defaultChecked={accept}
              type="checkbox"
              onClick={(e) => setAccept(e.target.checked)}
              className="form_check"
            />
          </div>
       
          <p className="terms_and_condition_content">
            Yes, I have read and accept the Terms & Conditions
          </p>
        </Col>
      </Row>
    </>
  );
};
export default AcceptTermsAndCondition;
