import React from "react";
import BackButton from "../../assets/icons/BackButton.svg";
import classes from "./index.module.css";
import ConditionNotes from "../../assets/icons/ConditionNotes.svg";
import {  Col, Row } from "react-bootstrap";
import AcceptTermsAndCondition from "./AcceptTermAndConditions";
const TermsAndConditions = (props) => {
  const { accept, setType, setAccept, disabledSubmit } = props;

  return (
    <>
      <Row>
        <Col lg={7}>
          <div className={classes.signUp_terms_and_condition}>
            <Row>
              <Col>
                <Row>
                  <Col xs={9} md={10}>
                    <div className={classes.back_button}>
                      <button
                        type="button"
                        onClick={() => {
                          setType(5);
                        }}
                        className={classes.btn}
                      >
                        <img src={BackButton} alt="back button" />
                      </button>
                    </div>
                  </Col>
                </Row>
                <div className={classes.Condition_Notes}>
                  <img src={ConditionNotes} alt="ConditionNotes" />
                </div>
                <div className={classes.title}>
                  <p>Do you accept the Junkee Terms & Conditions?</p>
                  <AcceptTermsAndCondition
                    accept={accept}
                    setAccept={setAccept}
                  />{" "}
                </div>
                <div className={classes.terms_and_conditions_btn}>
                  <button
                    type="button"
                    onClick={() => {
                      setType(15);
                    }}
                    className={classes.terms_and_condition_button}
                  >
                    <p> View Terms & Conditions</p>
                  </button>
                </div>
                <div className={classes.continue_button}>
                  <button
                    type="button"
                    disabled={disabledSubmit}
                    onClick={() => setType(7)}
                    className={classes.button_continue}
                  >
                    <p>Continue</p>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default TermsAndConditions;
