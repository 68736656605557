import { Col, Container, Row } from "react-bootstrap";
import classes from "./index.module.css";
import React from "react";
const EmailSignIn = (props) => {
  const { setType } = props;
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div className={classes.email_sign_up}>
              <button
                type=" button"
                onClick={() => {
                  setType(14);
                }}
                
                className={classes.btn}
              >
                 Use my email
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default EmailSignIn;
