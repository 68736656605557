import React from "react";
import classes from "./index.module.css";
import { FiLogOut } from "react-icons/fi";
const Logout = () => {
  const deleteInfo = () => {
    window.localStorage.clear();
    window.location.reload(false);
  };
  return (
    <div className={classes.logout_btn}>
      <button
        type="button"
        className={classes.btn}
        onClick={() => {
          deleteInfo();
        }}
      >
        <FiLogOut size={35} />
      </button>
    </div>
  );
};
export default Logout;
