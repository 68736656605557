import axios from "../../services/interceptor";
import { getUserNameAccordingToUrl } from "../../utils/helpers";

// used org global
export const getJunkeeAdminQuestions = async () => {
  const userId = localStorage.getItem("userId");
  let res;
  const name = await getUserNameAccordingToUrl();
  let apiUrl = `/posts/e1/user/forWebapp?closed=false&username=${name}`;

  if (userId) {
    apiUrl += `&viewerid=${userId}`;
  }

  res = await axios.get(apiUrl);
  if (res.data.length === 0) {
    res = await axios.get(
      `/posts/e1/user/forWebapp?closed=true&username=${name}`
    );
  }

  return res.data;
};

// used org without tag
export const getGlobalQuestionWithoutTag = async () => {
  const userId = localStorage.getItem("userId");
  let res;
  const name = await getUserNameAccordingToUrl();

  let apiUrl = `/posts/get/withoutTag/Post?closed=false&username=${name}`;

  if (userId) {
    apiUrl += `&viewerid=${userId}`;
  }

  res = await axios.get(apiUrl);
  if (res.data.length === 0) {
    res = await axios.get(
      `/posts/get/withoutTag/Post?closed=true&username=${name}`
    );
  }

  return res.data;
};

//  used org with tag
export const getQuestionByTag = async (params) => {
  let res;
  res = await axios.get(`/posts/get/tagPost`, {
    params: { ...params },
  });

  return res.data;

};

export const postAnswer = async (payload) => {
  const res = await axios.post("/posts/votes", payload);
  return res.data;
};
export const postComment = async (postId, comment) => {
  const res = await axios.post(`/posts/comments/${postId}`, comment);
  return res.data;
};

export const fetchAnswer = async (params) => {
  const res = await axios.get(`/posts/votes`, { params: { ...params } });
  return res.data;
};

export const getPostComment = async (params, closed) => {
  const res = closed
    ? await axios.get(`/posts/comments/post/${params.postId}`)
    : await axios.get(`/posts/auth/${params.postId} `);
  const response = closed ? res.data : res.data.userComments;
  return response;
};

export const commentLike = async (optionId, like) => {
  const res = await axios.post(`/posts/Like/${optionId}`, like);
  return res.data;
};
