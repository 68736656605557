import classes from "./index.module.css";
import React, { useEffect, useState } from "react";
import { getCategories } from "../../api/Categories";

const ListCategories = (props) => {
  const { userCategories, setUserCategories } = props;
  let [categories, setCategories] = useState([]);
  let [selected, setSelected] = useState([]);

  useEffect(async () => {
    const res = await getCategories();
    setCategories(res);
  }, [userCategories]);

  const handleClick = (id) => {
    if (selected.includes(id)) {
      let updatedArr = selected.filter((item) => item !== id);
      setSelected(updatedArr);
      setUserCategories(updatedArr);
    } else {
      setSelected((oldArray) => [...oldArray, id]);
      setUserCategories((oldArray) => [...oldArray, id]);
    }
  };
  return (
    <div className={classes.list_icons}>
      <div className={classes.scrolling_box} id ={classes.style}>
        <section id="1">
          {" "}
          {!categories
            ? "Loading..."
            : categories.map((value) => {
                return (
                  <div className={classes.icon_container}>
                    <div
                      id={`${value.category.id}`}
                      onClick={() => handleClick(value.category.id)}
                      tabindex="1"
                      className={` ${
                        selected.includes(value.category.id)
                          ? classes.outer_selected_box
                          : classes.outer_box
                      }`}
                    >
                      <div className={classes.icons}>{value.category.icon}</div>
                      <div className={classes.icon_title}>
                        {" "}
                        {value.category.title}{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
        </section>
      </div>
    </div>
  );
};
export default ListCategories;
