import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./index.module.css";
import DisableValidateIcon from "../../../assets/icons/PasswordError.svg";
import EnableValidateIcon from "../../../assets/icons/CheckboxPurple.svg";
const ValidationPassword = (props) => {
  const { passValidationCheck, signUp } = props;
  return (
    <Container>
      <Row>
        <Col>
          <div className={classes.validationBox}>
            <div
              className={
                passValidationCheck.minLengthPassword
                  ? classes.password_hint_list
                  : classes.password_disable_hint_list
              }
            >
              <img
                src={
                  passValidationCheck.minLengthPassword
                    ? EnableValidateIcon
                    : DisableValidateIcon
                }
                alt="userexists"
                className={classes.disablecheckboxSvg}
              />
              <p>Minimum 8 characters</p>
            </div>
            <div
              className={
                passValidationCheck.lowercasePassword &&
                passValidationCheck.uppercasePassword
                  ? classes.password_hint_list
                  : classes.password_disable_hint_list
              }
            >
              <img
                src={
                  passValidationCheck.lowercasePassword &&
                  passValidationCheck.uppercasePassword
                    ? EnableValidateIcon
                    : DisableValidateIcon
                }
                alt="userexists"
                className={classes.disablecheckboxSvg}
              />
              <p>Uppercase and lowercase characters </p>
            </div>
            <div
              className={
                passValidationCheck.digitsPassword
                  ? classes.password_hint_list
                  : classes.password_disable_hint_list
              }
            >
              <img
                src={
                  passValidationCheck.digitsPassword
                    ? EnableValidateIcon
                    : DisableValidateIcon
                }
                alt="userexists"
                className={classes.disablecheckboxSvg}
              />
              <p>Numbers and Symbols</p>
            </div>
            <div
              className={
                passValidationCheck.confirmPassMatch
                  ? classes.password_hint_list
                  : classes.password_disable_hint_list
              }
            >
              <img
                src={
                  passValidationCheck.confirmPassMatch &&
                  signUp.password.length > 1
                    ? EnableValidateIcon
                    : DisableValidateIcon
                }
                alt="userexists"
                className={classes.disablecheckboxSvg}
              />
              <p>Passwords match</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ValidationPassword;
