import React from "react";
import classes from "./index.module.css";
import EnableValidateIcon from "../../../assets/icons/CheckboxPurple.svg";
import cross from "../../../assets/icons/Cross.svg";
const UserExistMessage = ({ signUp, userExist }) => {
  return (
    <>
      {signUp.username.length >= 4 ? (
        <img
          src={userExist ? cross : EnableValidateIcon}
          alt="userexists"
          className={classes.check_box_icon}
        />
      ) : (
        ""
      )}

      {userExist && signUp.username.length >= 4 ? (
        <div className={classes.message}>
          <div className={classes.error_message}>
            <p className={classes.name_error}>
              Oh no! That username is unavailable!
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default UserExistMessage;
