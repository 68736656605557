import React from "react";
import classes from './index.module.css'
const ErrorMessage = () => {
  return (
    <div className={classes.message}>
      <div className={classes.error_message}>
        <div className={classes.name_error}>
        Oh no! You can only register for Wizer if you’re 13 or older.!
        </div>
      </div>
    </div>
  );
};
export default ErrorMessage;

