
import React from 'react';
import { Badge, Card, Container, Button } from 'react-bootstrap';
const NoPostFoundMessage = () => {
  return (
    <Container>
      <Card bg="light" style={{border:"1px solid #7c10a7"}} text="dark" className="text-center">
        <Card.Body>
          <Card.Title>
            <h4>
              Thank you for sharing your expertise!
            </h4>
          </Card.Title>
          <Card.Text>
            You are all caught up for now. Continue your journey in the Wizer Native apps and climb our leaderboards!
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button variant='secondary' target='_blank'  href='https://play.google.com/store/apps/details?id=com.wizerapp'  >Explore Native app!</Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};
export default NoPostFoundMessage;
