import classes from "./index.module.css";
import React, { useEffect, useState } from "react";
import BackButton from "../../assets/icons/BackButton.svg";
import Password from "../../assets/icons/Password.svg";
import { Col,  Row } from "react-bootstrap";
import ValidationPassword from "./ValidationPassword";
import Logout from "../Logout";
import hide from "../../assets/icons/pswdeye.svg";
import show from "../../assets/icons/showpswdeye.svg";

const SignUpPassword = (props) => {
  const { signUp, setType, handleSignUpChange } = props;
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [continueDisable, setContinueDisable] = useState(true);

  const [passValidationCheck, setPassValidationCheck] = useState({
    uppercasePassword: false,
    lowercasePassword: false,
    digitsPassword: false,
    minLengthPassword: false,
    confirmPassMatch: false,
  });

  useEffect(() => {
    const checkAllValidationPassed = Object.values(passValidationCheck).every(
      (value) => value === true
    );
    setContinueDisable(!checkAllValidationPassed);
  }, [passValidationCheck]);

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    // for password
    let passValidationValue = { ...passValidationCheck };
    if (passwordInputFieldName == "confirmPassword") {
      passValidationValue["confirmPassMatch"] =
        passwordInputValue === signUp.password ? true : false;
      setPassValidationCheck(passValidationValue);
    } else {
      Object.keys(passValidationCheck).map((key, value) => {
        if (key !== "confirmPassMatch") {
          let regexVal =
            key == "uppercasePassword"
              ? /(?=.*?[A-Z])/
              : key == "lowercasePassword"
              ? /(?=.*?[a-z])/
              : key == "digitsPassword"
              ? /(?=.*?[0-9])/
              : key == "minLengthPassword"
              ? /.{8,}/
              : "";
          passValidationValue[key] = regexVal.test(passwordInputValue);
        }
      });
      setPassValidationCheck(passValidationValue);
    }
  };

  return (
    <>
      <Row>
        <Col lg={7}>
          <div className={classes.Password}>
            <Row>
              <Col xs={9} md={10}>
                <div className={classes.back_button}>
                  <button
                    type="button"
                    onClick={() => {
                      setType(3);
                    }}
                    className={classes.btn}
                  >
                    <img src={BackButton} alt="back button" />
                  </button>
                </div>
              </Col>
              <Col xs={3} md={2}>
                <Logout />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={classes.SignUpSection}>
                  <img
                    src={Password}
                    alt="pswd"
                    className={classes.PasswordSvg}
                  />
                  <p>Pick a password!</p>
                </div>
                <div className={classes.InputField_input_placeholder}>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={signUp.password}
                    onChange={handleSignUpChange("password")}
                    onKeyUp={handleValidation}
                    className={classes.input_placeholder}
                  />
                  <img
                    src={showPassword ? show : hide}
                    className={classes.hide_show_password}
                    onClick={() => setShowPassword((prevState) => !prevState)}
                    alt="show password"
                  />
                </div>{" "}
                <Col>
                  <div>
                    <p className={classes.heading}>Confirm that password!</p>
                    <div className={classes.InputField_confirm_password}>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={signUp.confirmPassword}
                        onChange={handleSignUpChange("confirmPassword")}
                        onKeyUp={handleValidation}
                        className={classes.confirm_password}
                      />
                      <img
                        src={showConfirmPassword ? show : hide}
                        className={classes.hide_show_password}
                        onClick={() =>
                          setShowConfirmPassword((prevState) => !prevState)
                        }
                        alt="hide password"
                      />
                    </div>{" "}
                  </div>{" "}
                </Col>
                <ValidationPassword
                  signUp={signUp}
                  handleSignUpChange={handleSignUpChange}
                  passValidationCheck={passValidationCheck}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={classes.continue_button}>
                  <button
                    type="submit"
                    disabled={continueDisable}
                    onClick={() => setType(5)}
                    className={classes.button_continue}
                  >
                    <p>Continue</p>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default SignUpPassword;
