import React, { useState, useEffect } from "react";
import { googleSignIn } from "../../../api/Auth";
import axios from "axios";
import GoogleIcon from "../../../assets/icons/google.png";
import { Button, Col, Row } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import classes from "./index.module.css";
import { getPortalTypeAccordingToUrl } from "../../../utils/helpers";

import { createOrganizationMember } from "../../../api/Organization";

const GoogleSignUp = ({ setType, isPostClosed }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    async function getProfile() {
      if (user) {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        );
        setProfile(res.data);
        responseGoogle(res.data); // Call responseGoogle with the API response
      }
    }

    async function responseGoogle(response) {
    //  const portalName = await getPortalTypeAccordingToUrl();

      const { id, email, family_name, given_name, name, picture } = response;

      await googleSignIn({
        id,
        email: email || ".",
        familyName: family_name || ".",
        givenName: given_name || ".",
        name: name
          ? name
          : (given_name || "") + " " + (family_name || ""),
        photo: picture || ".",
        portalType: "wizer",
      });
      !isPostClosed && !isPostClosed ? setType(10) : setType(12);


    const orgId =  localStorage.getItem("organization_id");
 
    if(orgId)
    {
       await  createOrganizationMember({role: "user", organizationId: parseInt(orgId)});
    }
    }

    getProfile().catch((error) => console.log(error));



  }, [user]);
  return (
    <Row>
      <Col>
        <div className={classes.google_sign_up}>
          <img
            src={GoogleIcon}
            className={classes.google_icon}
            alt="Google icon"
          />
          <Button
            variant="light"
            className={classes.google_button}
            onClick={() => {
              login();
              setTimeout(() => {
                document.body.style.backgroundColor = "transparent";
              }, 2000);
            }}
            style={{
              color: "#000",
              width: "295px",
              marginRight: "0.9rem",
              border: "none",
              borderRadius: "0.25rem",
              background: "none",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <div className={classes.google_text}> Use Google</div>
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default GoogleSignUp;
