import React from "react";

const CircleProgressBar = ({ voteResult }) => {
  const strokeWidth = 10;
  const radius = 45;
  const circumference = radius * 2 * Math.PI;
  const gradientColors = [
    { color: "#4cc9f0", offset: "-3.55%" },
    { color: "#4895ef", offset: "14.74%" },
    { color: "#560bad", offset: "44.39%" },
    { color: "#b5179e", offset: "74.54%" },
    { color: "#f72585", offset: "91.34%" },
  ];

  // Calculate progress as a percentage of the maximum value in the gradientColors array
  const max = 100;
  const progress = (voteResult / max) * circumference;

  // Calculate the index of the color to use for the current progress value

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <svg width="120" height="120">
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="100%" y2="0">
            {gradientColors.map(({ color, offset }, index) => (
              <stop key={index} offset={offset} stopColor={color} />
            ))}
          </linearGradient>
        </defs>
        <circle
          stroke="#BDBDBD"
          strokeWidth={4}
          cx="60"
          cy="60"
          r={radius}
          fill="none"
        />
        <circle
          stroke={`url(#grad)`}
          strokeWidth={strokeWidth}
          strokeLinecap="butt"
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          strokeDasharray={`${progress} ${circumference}`}
          strokeDashoffset="0"
          transform={`rotate(-90, 60, 60)`}
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={25}
          fill="#000"
          style={{ fontFamily: "Montserrat", fontWeight: 700 }}
        >
          {voteResult}%
        </text>
      </svg>
    </div>
  );
};

export default CircleProgressBar;
