import classes from "./index.module.css";
import React from "react";
import backButton from "../../assets/icons/BackButton.svg";
import SignIn from "../../assets/icons/SignIn.svg";
import { Col, Row } from "react-bootstrap";
import EmailSignUp from "./EmailSignUp";
import GoogleSignUp from "./GoogleSignUp";
import LogInMe from "./LogInMe";
const SignUp = (props) => {
  const { setType, isPostClosed } = props;



  
  
  return (
      <>
        <Row>
          <Col md={7}>
            <div className={classes.sign_up}>
              {" "}
              <Row>
                <Col xs={9} md={10}>
                  <div className={classes.back_button}>
                    <button
                      type="button"
                      onClick={() => {
                        isPostClosed ? setType(2) : setType(1);
                      }}
                      className={classes.btn}
                    >
                      <img src={backButton} alt="back button" />
                    </button>
                  </div>
                </Col>
              </Row>
              <div>
                <div className={classes.sign_up_hint}>
                  You need to sign up before you can share your opinion!
                </div>
              </div>
              <div className={classes.sign_up_options}>
                <img
                  src={SignIn}
                  alt="back button"
                  className={classes.union_svg}
                />
                <div className={classes.sign_up_hint_text}>
                  How do you want to sign up?
                </div>
                <div>
                <EmailSignUp setType={setType} />
                <GoogleSignUp setType={setType} isPostClosed={isPostClosed} />
                <LogInMe setType={setType} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
  );
};
export default SignUp;
