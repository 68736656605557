import React from "react";
import classes from "./index.module.css";
import BackButton from "../../assets/icons/BackButton.svg";
import WelcomeUserIcon from "../../assets/icons/WelcomeUser.svg";
import { Col, Row } from "react-bootstrap";
import { postAnonymousDataToTable } from "../../api/Anonymous";
import { useAnonymousStatus } from "../../hooks/useAnonymousStatus";

const WelcomeUser = (props) => {
  const { setType, isPostClosed } = props;
  const anonymousDataString = localStorage.getItem("anonymousData");
  const anonymousData = JSON.parse(anonymousDataString);
  const anonymousStatus = useAnonymousStatus();
  const welcomeButtonHandler = async () => {
    const payload = { id: anonymousData ? anonymousData.map(String) : [] };
    if (anonymousStatus == true) {
      await postAnonymousDataToTable(payload);
      localStorage.removeItem("postAndOption");
      localStorage.removeItem("anonymousData");
    }
  };  

  const setTypeHandler = () => {

    setType(1);
    window.location.reload();
  };  
  return (
    <>
      <Row>
        <Col lg={7}>
          <div className={classes.welcome_user_screen}>
            <Row>
              <Col xs={9} md={10}>
                <div className={classes.back_button}>
                  <button
                    type="button"
                    onClick={() => {
                      setType(9);
                    }}
                    className={classes.btn}
                  >
                    <img src={BackButton} alt="back button" />
                  </button>
                </div>
              </Col>
              {/* {userId  && */}
              {/* <Col xs={2} md={2}>
                     <Logout/>
                  </Col> */}
              {/* } */}
            </Row>
            <Col>
              <div className={classes.user_icon}>
                <img src={WelcomeUserIcon} alt="user" />
              </div>{" "}
              <div className={classes.content}>
                Welcome,
                <span className={classes.name}>
                  {" "}
                  {` ${localStorage.getItem("username")}`} !
                </span>{" "}
              </div>{" "}
              <p className={classes.certified_title}>
                {" "}
                You’re now a certified Wizerd!
              </p>
              <div className={classes.continue_button}>
                <button
                  type="button"
                  onClick={() => {
                    if (isPostClosed) {
                      setType(12);
                    } else {
                      setTypeHandler();
                    }
                    welcomeButtonHandler();
                  }}
                  className={classes.button_continue}
                >
                  <p>Continue</p>
                </button>
              </div>
            </Col>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default WelcomeUser;
