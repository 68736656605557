import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./index.module.css";
import "./index.css";
import { postAnswer, fetchAnswer } from "../../api/Questions";
import {
  checkLoggedInUserAnswer,
  getPortalTypeAccordingToUrl,
  getUserNameAccordingToUrl,
} from "../../utils/helpers.js";
import QuestionContent from "./QuestionContent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultipleOptionsAndYesOrNoQuestion from "./MultipleOptionsAndYesOrNoQuestion";
import MultipleImageWithTextQuestion from "./MultipleImageWithTextQuestion";
import { getCategories } from "../../api/Categories";
import { BiTimer } from "react-icons/bi";
import { postAnonymousUserData } from "../../api/Anonymous";
import {
  PostCloseTime,
  MessageForAnnonymusUser,
  MessageOnVoteAnnonymusUser,
  // SignInMessage,
} from "../../Modals";
import { usePostRemainingTime, useAnonymousStatusOrganization } from "../../hooks";

const Questions = (props) => {
  const { setType, junkeeAdminQuestions, htmlcss } = props;
  const { id, mediasC, options, medias } = junkeeAdminQuestions.post;

  const { userId, token, username, optionsValue } = localStorage;
  const [shadow, setShadow] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [visible, setVisible] = useState([]);
  const [newShadow, setNewShadow] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showAnonymousModal, setShowAnonymousModal] = useState(false);
  const [backInmodalShow, setBackInModalShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [clickHandlerExecuted, setClickHandlerExecuted] = useState(false);
  const [showAnonymousUserVoteModal, setShowAnonymousUserVoteModal] = useState(
    false
  );

  const anonymousStatus = useAnonymousStatusOrganization();

  const postRemainingTime = usePostRemainingTime(
    junkeeAdminQuestions.post.expiresAt
  );
  const fetchData = async () => {
    try {
      if (userId) {
        const result = await fetchAnswer({
          postId: Object.keys(junkeeAdminQuestions).length >= 1 ? id : "",
        });

        const optionId = checkLoggedInUserAnswer(result, userId);
        if (optionId !== "") {
          setSelectedOption(optionId);
        }
      }

      if (token && username) {
        setModalShow(true);
      } else {
        if (!clickHandlerExecuted) {
          setTimeout(() => {
            setModalShow(true);
          }, 1000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      localStorage.removeItem("postId");
    }
    fetchData();
  }, [userId, token, username, clickHandlerExecuted]);

  useEffect(() => {
    const postId = junkeeAdminQuestions.post.id;
    const existingArray =
      JSON.parse(localStorage.getItem("postAndOption")) || [];
    const postAndOption = existingArray.find((item) => item.postId === postId);
    if (postAndOption) {
      setSelectedOption(postAndOption.optionValue);
    } else if (token && username) {
      setSelectedOption(localStorage.getItem("optionsValue"));
    }
  }, [junkeeAdminQuestions.post.id, token, username]);

  useEffect(() => {
    const fetchAnswerData = async () => {
      if (userId) {
        const result = await fetchAnswer({
          postId:
            Object.keys(junkeeAdminQuestions).length >= 1
              ? junkeeAdminQuestions.post.id
              : "",
        });
        const optionId = checkLoggedInUserAnswer(result, userId);
        if (optionId !== "") {
          setSelectedOption(optionId);
        }
      }
    };

    fetchAnswerData();
  }, [userId, junkeeAdminQuestions.post.id]);

  const questionType =
    Object.keys(junkeeAdminQuestions).length >= 1
      ? junkeeAdminQuestions.post.questionType
      : "";

  const handleClick = async (id) => {

    setClickHandlerExecuted(true);
    notify();
    setBackInModalShow(true);
    localStorage.setItem("optionsValue", id);
    if (!userId) {
      localStorage.setItem("postId", junkeeAdminQuestions.post.id);
    }
    if (!token && !username) {
      setSelectedOption(id);
      console.log(id)
    }
    if (token && username) {

      await postAnswer({
        postId: junkeeAdminQuestions.post.id,
        optionId: id,
      });
      setSelectedOption(id);
    } else {
      localStorage.setItem("optionsValue", id);
      setTimeout(() => {
        setType(2);
      }, 2000);
    }
  };
  const notify = () => {
    toast.success("Successfully voted!", {
      theme: "colored",
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
  };
  const fetchAnonymousUserDetail = async (params) => {
    try {
      const response = await postAnonymousUserData(params);
      const FROM_ID = response.anonymousVoteId;
      const existingData = localStorage.getItem("anonymousData");
      let dataArray = existingData ? JSON.parse(existingData) : [];

      if (dataArray.includes(FROM_ID)) {
      } else {
        dataArray.push(FROM_ID);
        localStorage.setItem("anonymousData", JSON.stringify(dataArray));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIpAddress = async () => {
    // const name = await getPortalTypeAccordingToUrl();
    try {
      const response = await fetch("https://api.ipify.org/?format=json");
      const data = await response.json();
      const optionsValue = localStorage.getItem("optionsValue");
      const params = {
        ip_address: data.ip,
        postid: id,
        post_option: Number(optionsValue),
        voterType: "public",
        rankingScore: 0,
        portal_type: "wizer",
        organization_id: localStorage.getItem("organization_id"),
      };

      fetchAnonymousUserDetail(params);
    } catch (error) {
      console.error(error);
    }
  };

  const anonymousHandleClick = async (id) => {
    localStorage.setItem("optionsValue", id);
    setClickHandlerExecuted(true);
    const postId = junkeeAdminQuestions.post.id;
    const existingArray =
      JSON.parse(localStorage.getItem("postAndOption")) || [];
    const postExists =
      existingArray.length > 0 &&
      existingArray.some((item) => item.postId === postId);

    if (!token && postExists) {
      setShowAnonymousModal(true);
    } else {
      notify();
      setSelectedOption(id);
      const postAndOption = [
        ...existingArray,
        { postId: postId, optionValue: id },
      ];
      localStorage.setItem("postId", postId);
      localStorage.setItem("postAndOption", JSON.stringify(postAndOption));

      if (!token && !username) {
        setSelectedOption(id);
      }

      if (token && username) {
        await postAnswer({ postId: postId, optionId: id });

        setSelectedOption(id);
      } else {
        setTimeout(() => {
          setShowAnonymousUserVoteModal(true);
          // setBackInModalShow(false);
        }, 2000);
      }
      await fetchIpAddress(id);
    }
  };




  const VisibleClickHandler = async (index, id) => {
    setNewShadow([index]);
    setVisible([index]);

    if (token && username) {
      await postAnswer({
        postId: junkeeAdminQuestions.post.id,
        optionId: id,
      });
      setSelectedOption(id);
    }
  };

  const handleCommentClick = () => {
    if (token && username) {
      setType(11);
    } else {
      setType(2);
    }
  };

  const newData = options.length > 0 ? options : options;

  const mediaData = [];
  medias.map((option, id) => {
    mediaData[id] = {
      mediaSource: option.mediaSource,
    };
  });
  const optionsData = [];
  {
    newData.map((option, index) => {
      optionsData[index] = {
        index: index,
        id: option.id,
        text: option.text,
        img: mediaData.length > 1 ? mediaData[index].mediaSource : "",
      };
    });
  }
  // category fetch for #

  useEffect(async () => {
    await getCategories().then((res) => setCategories(res));
  }, []);

  const specificList = categories.filter((category) => {
    return (
      category.category &&
      category.category.id === junkeeAdminQuestions.post.categoryId
    );
  });
  return (
    <>
    <Helmet>
      <meta property="og:title" content={junkeeAdminQuestions.post.question} />
      <meta property="og:description" content={junkeeAdminQuestions.post.question} />
      <meta property="og:url" content={"https://web.wizer.life/postid=" + junkeeAdminQuestions.post.id}  />
      <meta name="twitter:title" content={junkeeAdminQuestions.post.question} />
      <meta name="twitter:description" content={junkeeAdminQuestions.post.description} />
      <meta name="twitter:url" content={"https://web.wizer.life/postid=" + junkeeAdminQuestions.post.id} />
      <meta name="twitter:card" content="Summary" />
    </Helmet>
    <Container>
        <MessageOnVoteAnnonymusUser
          setType={setType}
          show={showAnonymousUserVoteModal}
          onHide={() => setShowAnonymousUserVoteModal(false)} />
        <MessageForAnnonymusUser
          setType={setType}
          show={showAnonymousModal}
          onHide={() => setShowAnonymousModal(false)} />
        <Row>
          <PostCloseTime
            show={backInmodalShow}
            onHide={() => setBackInModalShow(false)}
            postremainingtime={postRemainingTime} />


          <Col xxl={6} md={7} className={classes.scrollbar_question_screen}>
            <div className={classes.question_screen}>

              <div>
                <ToastContainer
                  style={{
                    color: "yellow",
                    maxWidth: "80vw",
                    top: "0px",
                    left: "50.62%",
                  }} />
              </div>



              <Row style={htmlcss && htmlcss.header && htmlcss.font}>
                <Col>
                  <div className={classes.question_heading} style={htmlcss && htmlcss.font}>
                    {newData.length >= 0 && junkeeAdminQuestions.post.question}
                  </div>
                </Col>

                <Col xs={2}>
                  <span className={classes.post_remain_timer} style={htmlcss && htmlcss.font}>
                    <BiTimer
                      style={{
                        height: "28px",
                        width: "28px",
                        transition: "all 0.3s ease",
                      }} />{" "}
                    <span className={classes.live_timer}>
                      {postRemainingTime
                        ? postRemainingTime.days > 0
                          ? postRemainingTime.days + "d"
                          : postRemainingTime.hours > 0
                            ? postRemainingTime.hours + "h"
                            : postRemainingTime.minutes > 0
                              ? postRemainingTime.minutes + "m"
                              : null
                        : null}
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  {mediasC.length > 1 ? (
                    <div
                      id={`image` + optionsData.length}
                      className={classes.image_grid_wrapper}
                      onClick={() => setShadow(true)}
                    >
                      {optionsData.map((data) => {
                        return (
                          <MultipleImageWithTextQuestion

                            shadow={shadow}
                            VisibleClickHandler={VisibleClickHandler}
                            visible={visible}
                            newShadow={newShadow}
                            selectedOption={selectedOption}
                            junkeeAdminQuestions={junkeeAdminQuestions}
                            handleClick={anonymousStatus === true && !token
                              ? anonymousHandleClick
                              : handleClick}
                            {...data}
                            notify={notify} />
                        );
                      })}
                    </div>
                  ) : (
                    <MultipleOptionsAndYesOrNoQuestion
                      htmlcss={htmlcss}
                      questionType={questionType}
                      junkeeAdminQuestions={junkeeAdminQuestions}
                      options={newData}
                      mediasC={mediasC}
                      medias={medias}
                      handleClick={anonymousStatus === true && !token
                        ? anonymousHandleClick
                        : handleClick}
                      notify={notify}
                      selectedOption={selectedOption} />
                  )}
                  <QuestionContent
                    footercss={htmlcss}
                    specificList={specificList}
                    junkeeAdminQuestions={junkeeAdminQuestions}
                    handleCommentClick={handleCommentClick} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container></>
  );
};
export default Questions;
