import axios from "../../services/interceptor";

export const signIn = async (payload) => {


  const res = await axios.post("/auth/signin", payload);
  if (res && !res.data.error) {
    localStorage.setItem("token", res.data.tokens.accessToken);
    localStorage.setItem("loginTime", Math.floor(Date.now() / 1000));
    localStorage.setItem("username", res.data.user.username);
    localStorage.setItem("email", res.data.user.email);
    localStorage.setItem("userId", res.data.user.id); 
    return res.data;
  }
};

export const logoutUser = () => {
  if (localStorage.getItem("token")) {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("email"); 
};
};

export const userSignUp = async (payload) => {
  const res = await axios.post("/auth/signup", payload);
  localStorage.setItem("username", res.data.user.username);
  localStorage.setItem("loginTime", Math.floor(Date.now() / 1000));
  localStorage.setItem("email", res.data.user.email);
  localStorage.setItem("userId", res.data.user.id); 
 
  return res.data;
};
export const checkCredential = async (payload) => {
  const res = await axios.get("/auth/check-credentials", {
    params: payload,
  });
  return res.data;
};

export const googleSignIn = async (payload) => {
  const res = await axios.post("/auth/google/signin", payload);
  localStorage.setItem("username", res.data.user.username);
  localStorage.setItem("loginTime", Math.floor(Date.now() / 1000));
  localStorage.setItem("email", res.data.user.email);
  localStorage.setItem("userId", res.data.user.id);
  localStorage.setItem("token", res.data.tokens.accessToken); 
 
  

  return res.data;
};
