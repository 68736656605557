import React, { useEffect, useState, useCallback } from "react";
import Comment from "../../assets/icons/Comments.svg";
import classes from "./index.module.css";
import { Row, Col } from "react-bootstrap";
import { fetchAnswer } from "../../api/Questions";
import { postResult } from "../../utils/helpers";
import CircleProgressBar from "../../utils/CircleProgressBar";

const Result = ({ junkeeAdminQuestions, setType }) => {
  const [voteResult, setVoteResult] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    await fetchVotes();
  }, [junkeeAdminQuestions]);

  const fetchVotes = useCallback(async () => {
    const votesByPostId = await fetchAnswer({
      postId: junkeeAdminQuestions.post.id,
    });

    const resultVote = await postResult(votesByPostId);
    setVoteResult(resultVote);
    setLoading(false);
  }, []);
  const QuestionType = junkeeAdminQuestions.post.questionType;

  const Image =
    junkeeAdminQuestions.post.mediasC.length == 0
      ? ""
      : junkeeAdminQuestions.post.mediasC[0].mediaSource;

  const post = junkeeAdminQuestions;
  const optionsC = [...post.post.optionsC];
  const winnerOptions = [...post.post.winnerOptions];
  const mediasC = [...post.post.mediasC];

  const merged = optionsC.reduce((arr, item) => {
    if (arr[0].winner == item.id) {
      arr.push(item);
    }
    return arr;
  }, winnerOptions);

  const mergedFinal = mediasC.reduce((arr, item) => {
    if (arr.length > 1 ? arr[1].mediaId == item.id : "") {
      arr.push(item);
    }
    return arr;
  }, merged);

  const resultImage =
    junkeeAdminQuestions.post.mediasC.length > 0 ? (
      <div className={classes.image}>
        {QuestionType == "multiple_choice" ? (
          <img src={mergedFinal[2].mediaSource} className={classes.img} alt= 'result' />
        ) : (
          <img src={Image} className={classes.img} alt= 'result' />
        )}
      </div>
    ) : (
      ""
    );
  const decision = junkeeAdminQuestions.post.owner.username;
  const videoIdentity =
    junkeeAdminQuestions.post.mediasC.length > 0
      ? junkeeAdminQuestions.post.mediasC[0].mediaType
      : "";
  const resultVideoAndImageHandler =
    videoIdentity == "video" ? (
      <div className={classes.image}>
        <video
          src={mediasC[0].mediaSource}
          autoPlay
          loop
          muted
          className={classes.img}
        />
      </div>
    ) : (
      resultImage
    );

  return (
    <>
      {!loading && (
        <Row className={classes.scrollbar_result_screen}>
          <Col lg={7}>
            <div className={classes.question_result}>
              <Row>
                <Col xs={2} md={4}></Col>
                <Col xs={4} md={6}>
                  {resultVideoAndImageHandler}
                </Col>
                <Col xs={3} md={2}>
                  <div className={classes.comment}>
                    <img src={Comment} onClick={() => setType(11)}  alt="comment"/>
                    <p>{junkeeAdminQuestions.post.comments}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className={classes.heading}>
                    <h3>{junkeeAdminQuestions.post.question}</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <div className={classes.outerBox}>
                    <h3 className={classes.vote_options}>
                      THE TOP VOTED OPTION WAS:
                    </h3>
                    <p className={classes.answer}>
                      {voteResult.decidedVoteText}
                    </p>

                    <Row style={{ paddingTop: "40px", paddingBottom: "30px" }}>
                      <Col xs={5} md={5}>
                        <div className={classes.circle}>
                          <CircleProgressBar
                            voteResult={voteResult.decidedVotePer}
                          />
                        </div>
                      </Col>

                      <Col xs={7} md={7}>
                        <div className={classes.votes}>
                          {`This was the top voted option with ${
                            !isNaN(voteResult.decidedVotePer)
                              ? voteResult.decidedVotePer
                              : 0
                          }% of the overall
                        votes!`}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <div className={classes.box}>
                    <p className={classes.decision}>{decision} DECISION</p>
                    <p className={classes.final_answer}>
                      {voteResult.decidedVoteText}
                    </p>
                  </div>
                </Col>
              </Row>
              <div className={classes.endline}></div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Result;
