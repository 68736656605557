import classes from "./index.module.css";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { postComment, getPostComment } from "../../api/Questions";
import { Col, Row } from "react-bootstrap";
import BackButton from "../../assets/icons/BackButton.svg";
import CommentAndLike from "./CommentsAndLike";
import Loading from "../Loading";
import "./index.css";
import { commentLike, commentReply } from "../../api/Comments";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Comment = (props) => {
  const { junkeeAdminQuestions, setType, isPostClosed } = props;
  const [postDisable, setPostDisable] = useState(true);
  const [postComments, setPostComments] = useState([]);
  const [commentPosted, setCommentPosted] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [reply, setReply] = useState(false);
  const [commentPlaceHolder, setCommentPlaceHolder] = useState("Add Comment");
  const [replyCommentIds, setReplyCommentIds] = useState({});

  const innerRef = useRef(null);
  useEffect(async () => {
    if (!isPostClosed) {
      toast.warning("You can't see comments until post is decided", {
        theme: "colored",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        
      });
    }
    if (Object.keys(junkeeAdminQuestions).length >= 1) {
      updateComments(junkeeAdminQuestions);
    }
    document.addEventListener("click", handleWindowClick);
    return () => {
      document.removeEventListener("click", handleWindowClick);
    };
  }, [junkeeAdminQuestions, commentPosted, innerRef]);

  const updateComments = useCallback(async (junkeeAdminQuestions) => {
    const response =
      (await Object.keys(junkeeAdminQuestions).length) >= 1
        ? await getPostComment(
            { postId: junkeeAdminQuestions.post.id },
            junkeeAdminQuestions.post.closed
          )
        : "";
    setPostComments(response);
    setLoading(false);
  }, []);
  const submitLike = async (id) => {
    await commentLike(id);
    window.location.reload(false);
  };

  const submitComment = async () => {
    await postComment(junkeeAdminQuestions.post.id, {
      text: comment,
    });

    setCommentPosted(true);
  };

  const onReplyClick = async (id, baseCommentId) => {
    setReply(true);
    setCommentPlaceHolder(" Reply to Comment");
    setComment("");
    setReplyCommentIds({ commentId: id, baseCommentId: baseCommentId });
  };

  const submitReply = async (id) => {
    const replyData = {
      text: comment,
      baseCommentId:
        replyCommentIds.baseCommentId !== null
          ? replyCommentIds.baseCommentId
          : replyCommentIds.commentId,
      replyToCommentId: replyCommentIds.commentId,
    };
    await commentReply(id, replyData);
    setCommentPosted(true);
    window.setTimeout(function() {
      window.location.reload();
    }, 3000);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setPostDisable(false);
  };

  const notify = () => {
    toast.success("Successfully comment!", {
      theme: "colored",
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
  };

  const handleWindowClick = (event) => {
    if (
      innerRef.current &&
      innerRef.current.contains(event.target) &&
      event.target.id !== "reply"
    ) {
      setReply(false);
      setCommentPlaceHolder(" Post Comment");
    }
  };

  return (
    <>
      {!loading ? (
        <Row>
          <ToastContainer
            className="toast_css"
            style={{
              color: "yellow",
              maxWidth: "80vw",
              top: "0px",
              left: "50.62%",
            }}
          />

          <Col>
            <div className={classes.Comment}>
              <Col>
                <div ref={innerRef}>
                  <Row>
                    <Col xs={1} md={1}>
                      <div className={classes.back_button}>
                        <button
                          type="button"
                          onClick={() => {
                            junkeeAdminQuestions.post.closed
                              ? setType(12)
                              : setType(1);
                          }}
                          className={classes.btn}
                        >
                          <img src={BackButton} alt="back button" />
                        </button>
                      </div>
                    </Col>
                    <Col xs={11} md={11}>
                      <div className={classes.heading}>
                        <h4>
                          {Object.keys(junkeeAdminQuestions).length >= 1 &&
                            junkeeAdminQuestions.post.question}
                        </h4>
                      </div>
                    </Col>
                    {/* {userId && (
                      <Col xs={2} md={2}>
                        <Logout />
                      </Col>
                    )} */}
                  </Row>
                  <div className={classes.alignment}>
                    <div className={classes.bottom_outerBox}></div>

                    <div className={classes.scrollbar} id={classes.style}>
                      <div className={classes.force_overflow}></div>
                      <div className={classes.total_comment}>
                        {isPostClosed
                          ? `${postComments.length} comment(s)`
                          : ""}
                      </div>{" "}
                      {postComments.length > 0 &&
                        postComments.map((comment, index) => (
                          <div>
                            <CommentAndLike
                              submitLike={submitLike}
                              comment={comment}
                              key={comment.id}
                              isPostClosed={isPostClosed}
                              onReplyClick={onReplyClick}
                              reply={reply}
                            />{" "}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className={classes.bottom_outerBox}></div>
                <Row>
                  {isPostClosed ? (
                    <>
                      <Col md={10}>
                        <div className={classes.comment_field}>
                          <input
                            type="text"
                            name="comment"
                            maxLength={120}
                            placeholder={commentPlaceHolder}
                            value={comment}
                            onChange={handleCommentChange}
                            className={classes.input_placeholder}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className={classes.post_btn}>
                          <button
                            type="button"
                            disabled={postDisable}
                            onClick={() => {
                              reply
                                ? submitReply(junkeeAdminQuestions.post.id)
                                : submitComment();
                              notify();
                            }}
                            className={classes.btn_post}
                          >
                            <p>Post</p>
                          </button>
                        </div>
                      </Col>
                    </>
                  ) : (
                    postComments.length == 0 && (
                      <>
                        <Col md={10}>
                          <div className={classes.comment_field}>
                            <input
                              type="text"
                              name="Comment"
                              placeholder="  Add a Comment"
                              value={comment}
                              onChange={handleCommentChange}
                              className={classes.input_placeholder}
                            />
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className={classes.post_btn}>
                            <button
                              type="button"
                              disabled={postDisable}
                              onClick={() => {
                                reply
                                  ? submitReply(junkeeAdminQuestions.post.id)
                                  : submitComment();
                                notify();
                              }}
                              className={classes.btn_post}
                            >
                              <p>Post</p>
                            </button>
                          </div>
                        </Col>
                      </>
                    )
                  )}
                </Row>
              </Col>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
};
export default Comment;
