import { Col, Row } from "react-bootstrap";
import React from "react";
import "./index.css";
const AgreePrivacyPolicy = (props) => {
  const { agree, setAgree } = props;

  return (
    <>
      <Row>
        <Col lg={7} xl={7}>
          <div className="privacy-section">
            <div className="privacy_policy_sub_title">
              <input
                defaultChecked={agree}
                type="checkbox"
                onClick={(e) => setAgree(e.target.checked)}
                className="form_check"
              />
            </div>

            <p className="privacy_policy_content">
              Yes, I agree to Junkee collecting my data
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default AgreePrivacyPolicy;
