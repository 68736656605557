import { useState, useEffect } from "react";

export function usePostRemainingTime(expiresAt) {
  const [postRemainingTime, setPostRemainingTime] = useState(null);

  useEffect(() => {
    let intervalId;
    if (expiresAt) {
      intervalId = setInterval(() => {
        let currentTime = new Date(); //gets the current time
        let currentTimeUTC = new Date(currentTime.toUTCString()); //converts to UTC
        let currentTimeEpoch = currentTimeUTC.getTime() / 1000; //converts to epoch time

        let expiredAtEpoch = expiresAt;

        let timeLeft = expiredAtEpoch - currentTimeEpoch; //subtract current time from expiration time

        if (timeLeft <= 0) {
          clearInterval(intervalId);
          return;
        }

        let date = new Date(timeLeft * 1000);

        const timeOptions = { timeZone: "UTC" };
        const formatter = new Intl.DateTimeFormat([], timeOptions);
        const dateTime = formatter.format(date);

        var hours = Math.floor(timeLeft / 3600);
        var days = 0;
        if (hours > 12) {
          days = Math.floor(hours / 24);
          hours = hours % 24;
        }
        timeLeft %= 3600;
        var minutes = Math.floor(timeLeft / 60);
        var seconds = timeLeft % 60;

        setPostRemainingTime({
          dateTime: dateTime,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [expiresAt]);

  return postRemainingTime;
}
