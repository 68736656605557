import React from "react";
import classes from "./index.module.css";
import AgreePrivacyPolicy from "./AgreePrivacyPolicy";
import { Col,  Row } from "react-bootstrap";
import BackButton from "../../assets/icons/BackButton.svg";
import PrivacyPolicyIcon from "../../assets/icons/PrivacyPolicy.svg";

const PrivacyPolicy = (props) => {
  const { agree, setType, setAgree, disabledSubmit } = props;
  return (
      <>
        <Row>
          <Col lg={7}>
            <div className={classes.privacy_policy_screen}>
              <Row>
                <Col>
                  <Row>
                    <Col xs={9} md={10}>
                      <div className={classes.back_button}>
                        <button
                          type="button"
                          onClick={() => {
                            setType(6);
                          }}
                          className={classes.btn}
                        >
                          <img src={BackButton} alt="back button" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <div className={classes.privacy_policy_icon}>
                    <img src={PrivacyPolicyIcon} alt="privacy policy" />
                  </div>
                  <div className={classes.title}>
                    <p>
                      {" "}
                      By signing up you agree to the collection of your data by
                      Junkee
                    </p>
                    <p className={classes.contents}>
                      By signing up you agree for Junkee to collect data such as
                      your name, email address, and usage habits. Please read
                      the Junkee privacy policy below for more information about
                      what data is collected and what it’s used for.
                    </p>
                    <AgreePrivacyPolicy agree={agree} setAgree={setAgree} />
                  </div>
                  <div className={classes.privacy_policy_btn}>
                    <button
                      type="button"
                      onClick={() => {
                        setType(15);
                      }}
                      className={classes.privacy_policy_button}
                    >
                      <p className={classes.privacy_policy_button_title}>
                        View Privacy Policy
                      </p>
                    </button>
                  </div>
                  <div className={classes.continue_button}>
                    <button
                      type="submit"
                      disabled={disabledSubmit}
                      name="submit"
                      id="submitButton"
                      onClick={() => setType(8)}
                      className={classes.button_continue}
                    >
                      <p>Continue</p>
                    </button>
                  </div>{" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
  );
};
export default PrivacyPolicy;
