import axios from "../../services/interceptor";

export const postAnonymousUserData = async (params) => {
  const res = await axios.post(`/dashboard/create/anonymousvote`, params);
  return res.data;
};

export const postAnonymousDataToTable = async (params) => {
  const res = await axios.post(
    `/dashboard/transfer/anonymousvote/toPostVotetable`,
    params
  );
  return res.data;
};

export const anonymousStatusCheck = async (params) => {
  const { portal } = params || {};
  const res = await axios.get(
    `/dashboard/get/Portal/anonymousvote/status?portal=${portal}`
  );
  return res.data;
};
