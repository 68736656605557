import { getDomainDtl } from "../api/Organization";

export const arrayToObject = (arr) => {
  var objs = arr.map((x) => ({
    id: x,
  }));
  return objs;
};

export const secondsToHms = (d) => {
  // get total seconds between the times
  let dateNow = Math.floor(new Date() / 1000);
  var delta = Math.abs(dateNow - d);

  // calculate (and subtract) whole days
  var d = Math.floor(delta / 86400);
  delta -= d * 86400;

  // calculate (and subtract) whole hours
  var h = Math.floor(delta / 3600) % 24;
  delta -= h * 3600;

  // calculate (and subtract) whole minutes
  var m = Math.floor(delta / 60) % 60;
  delta -= m * 60;

  // what's left is seconds
  var s = delta % 60; // in theory the modulus is not required
  var hDisplay = h > 0 && d == 0 ? h + (h == 1 ? " h " : " h ") : "";
  var mDisplay = m > 0 && h == 0 && d == 0 ? m + (m == 1 ? " m " : " m ") : "";
  var sDisplay =
    s > 0 && m == 0 && h == 0 && d == 0 ? s + (s == 1 ? " s" : " s") : "";
  var dDisplay = d > 0 ? d + (d == 1 ? "d" : "d") : "";

  return hDisplay + mDisplay + sDisplay + dDisplay;
};

export const checkLoggedInUserAnswer = (comments, userId) => {
  let optionId = "";
  comments.length > 0 &&
    comments.map((comment) => {
      if (comment.user.id == userId) {
        optionId = comment.votes[0].option.id;
      }
    });
  return optionId;
};

export const postResult = (votesByPostId) => {
  let totalVotes = votesByPostId.length;
  let votes =
    votesByPostId.length > 0 &&
    votesByPostId.map((vote) => {
      return vote.votes[0].option.text;
    });
  const countVotes = {};
  votes.length > 0 &&
    votes.forEach(function(x) {
      countVotes[x] = (countVotes[x] || 0) + 1;
    });
  const maxVotes =
    votes.length > 0
      ? Object.keys(countVotes).reduce((a, b) =>
          countVotes[a] > countVotes[b] ? a : b
        )
      : "";
  const maxVoteCount = countVotes[maxVotes];

  const decidedVotePer = parseInt((maxVoteCount * 100) / totalVotes);
  const result = { decidedVoteText: maxVotes, decidedVotePer };
  return result;
};

export const checkAge = (year) => {
  var today = new Date();
  let age = today.getFullYear() - year;
  if (age < 13) {
    return false;
  } else if (age > 90) {
    return false;
  } else {
    return true;
  }
};

export const checkUserPostedComment = (postComments, userId) => {
  const userArr = postComments.map((comment) => {
    return comment && comment.from && comment.from.user.id;
  });
  return userArr.includes(parseInt(userId)) ? true : false;
};

export const getUserNameAccordingToUrl = async () => {
  var parenturl =
    window.location != window.parent.location
      ? document.referrer
      : document.location.href;

  // Kyldoc -- personal account
  //Wizer -- admin account
  const domainMapping = new Map([
    ["https://wizer.life/", "Wizer"],
    ["https://winepilot.com/", "winepilot"],
    ["https://tech-diversity.com.au/", "TechDiversity"],
  ]);
  let username = domainMapping.get(parenturl) || "wizer";

  return username;
};

export const getPortalTypeAccordingToUrl = async () => {
  var parenturl =
    window.location != window.parent.location
      ? document.referrer
      : document.location.href;

  const domainMapping = new Map([
    ["https://wizer.life/", "wizer"],
    ["https://winepilot.com/", "winepilot"],
    ["https://tech-diversity.com.au/", "techdiversity"],
  ]);

  let portalType = domainMapping.get(parenturl) || "wizer";
  return portalType;
};

export const getOrganizationDtlAccordingToDomain = async () => {
  var parenturl =
    window.location != window.parent.location
      ? document.referrer
      : document.location.href;
      const urlObj = new URL(parenturl);
      let domain = urlObj.origin;
      
      if (!domain.endsWith('/')) {
        domain += '/';
      }
  
  const result = await getDomainDtl({
    domain: domain,
  });

  return result;
};
