import React, { useState, useEffect, useCallback } from "react";
import {
  fetchAnswer,
  getGlobalQuestionWithoutTag,
  getJunkeeAdminQuestions,
  getQuestionByTag,
} from "../api/Questions";

import {
  getOrganizationGlobalPost,
  getOrganizationPost,
  getOrganizationPostwithTag,
} from "../api/Organization";
import SignUp from "../Screens/SignUp/index";
import Cateogory from "../Screens/Cateogory";
import WelcomeUser from "../Screens/WelcomeUser";

import "./index.css";
import Question from "../Screens/Questions";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import SignUpPassword from "../Screens/SignUpPassword";
import SignUpBirthday from "../Screens/SignUpBirthday";
import SignUpUserName from "../Screens/SignUpUserName";
import TermsAndConditions from "../Screens/SignUpTermsAndConditions/index";
import Comment from "../Screens/Comment";
import Result from "../Screens/Result";
import Login from "../Screens/Login";
import SignIn from "../Screens/SignIn";
import Loading from "../Screens/Loading";
import TermAndConditionContent from "../Screens/TermAndConditionContent";
import SinglePageSignUp from "../Screens/SinglePageSignUp";
import PrivacyContent from "../Screens/PrivacyContent";
import { logoutUser } from "../api/Auth";
import {
  checkLoggedInUserAnswer,
  getPortalTypeAccordingToUrl,
  getUserNameAccordingToUrl,
} from "../utils/helpers";
import NoPostFoundMessage from "../Screens/NoPostFoundMsg";

const MainContainer = ({
  postIdVal,
  unAuthpost,
  parentUrlRef,
  baseUrl,
  htmlcss,
}) => {
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [type, setType] = useState(
    JSON.parse(localStorage.getItem("screen"))
      ? JSON.parse(localStorage.getItem("screen"))
      : 1
  );
  const [accept, setAccept] = useState(false);
  const [agree, setAgree] = useState(false);
  const [signUp, setSignUp] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    dob: "",
    username: "",
  });

  const [junkeeAdminQuestions, setJunkeeAdminQuestions] = useState({});

  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState();
  const [noPostMesssageDisplay, setNoPostMesssageDisplay] = useState(false);

  let errorFound;
  if (postIdVal) {
    errorFound = !unAuthpost.post && unAuthpost.error.errorCode === 117;
  }

  useEffect(() => {
    if (errorFound) {
      if (window.parent !== window) {
        window.parent.location.href = baseUrl;
      }
    }

    const currTime = Math.floor(Date.now() / 1000);
    const loggedTime = currTime - localStorage.getItem("loginTime");
    if (Math.floor(loggedTime) > 86400) {
      logoutUser();
    }
  }, [signUp]);
  const getTagForPost = localStorage.getItem("getTagForPost");

  const postId = localStorage.getItem("postId");
  const calculateFinalTagResponse = async () => {
    const organization_id = localStorage.getItem("organization_id");

    // const portalNamePromise = getPortalTypeAccordingToUrl();
    // const namePromise = getUserNameAccordingToUrl();
    const tagString = getTagForPost;

    // const [portalName, name] = await Promise.all([
    //   portalNamePromise,
    //   namePromise,
    // ]);

    const params = {
      tag: tagString,
      organizationId: organization_id,
      role: "admin",
      closed: false,
      visibility: "public",
      viewerid: userId,
    };

    let tagResponse = await getOrganizationPostwithTag(params);

    const tagResponseQuestionsType = tagResponse.filter((question) => {
      const questionType = question.post.questionType;
      return questionType === "yes_or_no" || questionType === "multiple_choice";
    });

    let selectedTagQuestions;
    if (localStorage.getItem("token")) {
      selectedTagQuestions = tagResponseQuestionsType.filter(
        (q) => q.post.id == postId
      );
    }

    const selectedTagQuestionsFlagZero = tagResponseQuestionsType.filter(
      (q) => q.flag === 0
    );

    const questionTagFinalResponse =
      selectedTagQuestions !== undefined
        ? selectedTagQuestions && selectedTagQuestions
        : selectedTagQuestionsFlagZero;

    let finalTagResponse = null;

    if (getTagForPost && questionTagFinalResponse.length > 0) {
      const randomTagIndex = Math.floor(
        Math.random() * questionTagFinalResponse.length
      );
      const randomTagPost = questionTagFinalResponse[randomTagIndex];
      finalTagResponse = randomTagPost;
    }

    return finalTagResponse;
  };

  const calculateFinalWithoutTagResponse = async () => {
    const questionResponseWithoutTag = await getOrganizationPost();

    const filteredQuestionsType = questionResponseWithoutTag.filter(
      (question) => {
        const questionType = question.post.questionType;
        return (
          questionType === "yes_or_no" || questionType === "multiple_choice"
        );
      }
    );

    let selectedWithoutTagQuestions;
    if (localStorage.getItem("token")) {
      selectedWithoutTagQuestions = filteredQuestionsType.filter(
        (question) => question.post.id == postId
      );
    }

    const selectedWithoutTagQuestionsFlagZero = filteredQuestionsType.filter(
      (q) => q.flag === 0
    );
    const questionWithoutTagFinalResponse =
      selectedWithoutTagQuestions && selectedWithoutTagQuestions !== undefined
        ? selectedWithoutTagQuestions
        : selectedWithoutTagQuestionsFlagZero;

    let finalWithoutTagResponse = null;

    if (questionWithoutTagFinalResponse.length > 0) {
      const allClosed = questionWithoutTagFinalResponse.every(
        (q) => q.post.closed === true
      );

      const randomWithoutTagIndex = allClosed
        ? 0
        : Math.floor(Math.random() * questionWithoutTagFinalResponse.length);

      const randomTagPost = filteredQuestionsType
        ? questionWithoutTagFinalResponse[randomWithoutTagIndex]
        : null;

      finalWithoutTagResponse = randomTagPost;
    }

    return finalWithoutTagResponse;
  };

  const calculateQuestionToDisplay = async () => {
    const questionResponse = await getOrganizationGlobalPost();

    const questionResponseQuestionsType = questionResponse.filter(
      (question) => {
        const questionType = question.post.questionType;
        return (
          questionType === "yes_or_no" || questionType === "multiple_choice"
        );
      }
    );
    const selectedQuestions = postId
      ? questionResponseQuestionsType.filter(
        (question) => question.post.id == postId
      )
      : [];

    const questionResponseFlagZero = questionResponseQuestionsType.filter(
      (q) => q.flag === 0
    );
    const finalQuestionWithOutZero =
      questionResponseFlagZero.length > 0
        ? questionResponseFlagZero
        : questionResponseQuestionsType;

    const questionFinalResponse =
      selectedQuestions.length > 0
        ? selectedQuestions
        : finalQuestionWithOutZero;

    const questionsWithFlagZero = questionFinalResponse.filter(
      (q) => q.flag === 0
    );

    let questionToDisplay = null;

    if (questionsWithFlagZero.length > 0) {
      questionToDisplay = getRandomElement(questionsWithFlagZero);
    } else {
      questionToDisplay = getRandomElement(questionFinalResponse);
    }

    return questionToDisplay;
  };

  const getRandomElement = (array) => {
    const allClosed = array.every((q) => q.post.closed === true);

    const randomIndex = Math.floor(Math.random() * array.length);
    return array[allClosed ? 0 : randomIndex];
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        let finalTagResponse = null;

        if (getTagForPost) {
          finalTagResponse = await calculateFinalTagResponse();
        }
        if (finalTagResponse) {
          setJunkeeAdminQuestions(finalTagResponse);
        } else {
          const finalWithoutTagResponse = await calculateFinalWithoutTagResponse();

          if (finalWithoutTagResponse !== null) {
            setJunkeeAdminQuestions(finalWithoutTagResponse);
          } else {
            const questionToDisplayResponse = await calculateQuestionToDisplay();
            setJunkeeAdminQuestions(questionToDisplayResponse);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }

    if (postIdVal && postIdVal !== null) {
      setLoading(false);
      setJunkeeAdminQuestions(unAuthpost);
    } else {
      fetchData();
    }
  }, [postIdVal, unAuthpost, getTagForPost, userId]);

  const handleSelectedOption = useCallback(
    (optionId) => {
      setSelectedOption(optionId);
    },
    [setSelectedOption]
  );

  useEffect(() => {
    if (
      (!localStorage.getItem("token") && junkeeAdminQuestions == null) ||
      undefined
    ) {
      setType(2);
    } else {
      if (
        (localStorage.getItem("token") && junkeeAdminQuestions == null) ||
        undefined
      ) {
        setNoPostMesssageDisplay(true);
      } else {
        const { post: { closed } = {} } = junkeeAdminQuestions;
        if (closed && type !== 11 && userId) {
          setType(12);
        } else if (!userId && closed) {
          setType(type >= 1 ? (type == 1 ? 2 : type) : 2);
        }
        async function fetchData() {
          try {
            if (localStorage.getItem("userId")) {
              const postId =
                Object.keys(junkeeAdminQuestions).length >= 1
                  ? junkeeAdminQuestions.post.id
                  : "";
              const result = await fetchAnswer({ postId });

              const optionId = checkLoggedInUserAnswer(
                result,

                localStorage.getItem("userId")
              );
              if (optionId) {
                handleSelectedOption(optionId);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }
    }
  }, [junkeeAdminQuestions]);

  const [userCategories, setUserCategories] = useState([]);
  const handleSignUpChange = (type) => (e) => {
    let newSignUpDetail = { ...signUp };
    if (type == "dob") {
      let dobVal = e.target.value;
      newSignUpDetail[type] = isNaN(parseInt(dobVal)) ? 0 : parseInt(dobVal);
    } else {
      newSignUpDetail[type] = e.target.value;
    }
    setSignUp(newSignUpDetail);
  };

  const isPostClosed =
    junkeeAdminQuestions &&
    Object.keys(junkeeAdminQuestions).length >= 1 &&
    junkeeAdminQuestions.post &&
    junkeeAdminQuestions.post.closed;
  return (
    <>
      {!loading && !noPostMesssageDisplay ? (
        <div className="app-wrapper">
          {type === 1 && junkeeAdminQuestions && (
            <Question
              htmlcss={htmlcss}
              setType={setType}
              junkeeAdminQuestions={junkeeAdminQuestions}
              userId={userId}
            />
          )}
          {type === 2 && (
            <SignUp setType={setType} isPostClosed={isPostClosed} />
          )}
          {type === 3 && (
            <SinglePageSignUp
              signUp={signUp}
              setType={setType}
              setSignUp={setSignUp}
              handleSignUpChange={handleSignUpChange}
              accept={accept}
              setAccept={setAccept}
              disabledSubmit={!accept}
              agree={agree}
              setAgree={setAgree}
            // disabledSubmit={!agree}
            />
          )}
          {type === 4 && (
            <SignUpPassword
              signUp={signUp}
              setSignUp={setSignUp}
              setType={setType}
              handleSignUpChange={handleSignUpChange}
            />
          )}
          {type === 5 && (
            <SignUpBirthday
              signUp={signUp}
              setType={setType}
              handleSignUpChange={handleSignUpChange}
            />
          )}
          {type === 6 && (
            <TermsAndConditions
              accept={accept}
              setType={setType}
              setAccept={setAccept}
              disabledSubmit={!accept}
            />
          )}
          {type === 7 && (
            <PrivacyPolicy
              agree={agree}
              setType={setType}
              setAgree={setAgree}
              disabledSubmit={!agree}
            />
          )}
          {type === 8 && (
            <SignUpUserName
              signUp={signUp}
              setType={setType}
              handleSignUpChange={handleSignUpChange}
            />
          )}
          {type === 9 && (
            <Cateogory
              signUp={signUp}
              setType={setType}
              userCategories={userCategories}
              setUserCategories={setUserCategories}
            />
          )}
          {type === 10 && (
            <WelcomeUser
              setType={setType}
              signUp={signUp}
              userCategories={userCategories}
              userId={userId}
              isPostClosed={isPostClosed}
            />
          )}
          {type === 11 && (
            <Comment
              setType={setType}
              junkeeAdminQuestions={junkeeAdminQuestions}
              userId={userId}
              isPostClosed={isPostClosed}
            />
          )}
          {type === 12 && (
            <Result
              setType={setType}
              junkeeAdminQuestions={junkeeAdminQuestions}
              userId={userId}
              isPostClosed={isPostClosed}
            />
          )}
          {type === 13 && (
            <SignIn
              setType={setType}
              junkeeAdminQuestions={junkeeAdminQuestions}
            />
          )}
          {type === 14 && (
            <Login
              setType={setType}
              junkeeAdminQuestions={junkeeAdminQuestions}
            />
          )}
          {type === 15 && <TermAndConditionContent setType={setType} />}
          {type === 16 && <PrivacyContent setType={setType} />}
        </div>
      ) : noPostMesssageDisplay ? (
        <NoPostFoundMessage />
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
};
export default MainContainer;
