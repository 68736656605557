import React from "react";
import iosDownload from "../../assets/icons/app-store-badge.svg";
import androidDownload from "../../assets/images/google-play-badge.png";
import WizerImage from "../../assets/images/wizer-footer.png";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="bg-linear-gradient">
      <Container>
        <Row>
          <Col xs={3} className="download-text ">
            <div>
              Download
              <img src={WizerImage} className="download-img"  alt="wizer"/>
            </div>
          </Col>
          <Col xs={3}>
            <div className=" footer-text-center justify-content-end footer-text ">
              The experience is better in the app!
            </div>
          </Col>
          <Col xs={3} className="d-flex  align-items-center download-links">
            <a href="https://apps.apple.com/us/app/wizer/id1584085846">
              {" "}
              <img
                src={iosDownload}
                className="ios-image"
                alt="download ios"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.wizerapp">
              <img
                src={androidDownload}
                className="android-image"
                alt="download android"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
