import React from "react";
import { Col, Form } from "react-bootstrap";

const PrivacyAndTermConditions = ({
  agree,
  setAgree,
  setAccept,
  accept,
  setType,
}) => {
  return (
    <>
      <Col xs={12}>
        <h6 className="w-all text-center text-bold text-color montserrat-font mt-4">
          Do you accept the Wizer Terms & Conditions & Privacy Policy?
        </h6>
      </Col>
      <Col xs={12} className="mb-2 mt-2  d-flex align-items-center">
        <Form.Check
          type="checkbox"
          className={
            agree == false
              ? "w-all big-checkbox checkbox-text"
              : "w-all color-checkbox checkbox-text"
          }
          onClick={(e) => setAgree(e.target.checked)}
          defaultChecked={agree}
          label={
            <>
              <>Yes, I have read and accept the</>{" "}
              <span
                className="link-termscondition"
                onClick={() => {
                  setType(15);
                }}
              >
                Terms & Conditions
              </span>
            </>
          }
        />
      </Col>
      <Col xs={12}>
        <Form.Check
          type="checkbox"
          onClick={(e) => setAccept(e.target.checked)}
          defaultChecked={accept}
          className={
            accept == false
              ? "w-all big-checkbox checkbox-text"
              : "w-all color-checkbox checkbox-text"
          }
          // label="Yes, I have read and accept the Privacy Policy"
          label={
            <>
              <>Yes, I have read and accept the</>{" "}
              <span
                className="link-termscondition"
                onClick={() => {
                  setType(16);
                }}
              >
                Privacy Policy
              </span>
            </>
          }
        />
      </Col>
    </>
  );
};

export default PrivacyAndTermConditions;
