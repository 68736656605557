import React, { useEffect } from "react";
import classes from "./index.module.css";
import userImage from "../../../assets/icons/defaultImage.svg";
import unLike from "../../../assets/icons/unLikeIcon.svg";
import like from "../../../assets/icons/likeIcon.svg";
import { secondsToHms } from "../../../utils/helpers.js";
import { Container, Col, Row } from "react-bootstrap";

const CommentAndLike = (props) => {
  const {
    comment,
    expirationDate,
    isPostClosed,
    submitLike,
    onReplyClick,
    replies,
    setExpireTime,
    expireTime,
    index,
    postOwner,
  } = props;
  const postTime = secondsToHms(comment.createdAt);

  useEffect(() => {
    const expireDiff = expirationDate - comment.createdAt;
    if (expireDiff < 0) {
      setExpireTime((oldArray) => [...oldArray, index]);
    }

  }, []);
  return (
    <Container>
      <Row>
        <Col>
          {index == Math.min.apply(null, expireTime) ? (
            <div className={classes.postOwner}>
              <span>{postOwner}</span>
            </div>
          ) : (
            ""
          )}{" "}
        </Col>
        <Col md={12}>
          <div className={classes.sidebar}>
            <Row>
              {" "}
              <Col  xs={1} md={1}>
                <div
                // className={classes.usericon}
                >
                  <img
                    src={userImage}
                    // className={classes.user}
                    style={{ width: 40, height: 40 }}
                   
                  />
                </div>
              </Col>
              <Col xs={9} md={9}>
                <div className={classes.comment_section}>
                  {isPostClosed &&
                    (!replies ? (
                      <div
                        className={classes.username}
                      >{`@${comment.from.user.username}`}</div>
                    ) : (
                      <h6 className={classes.username}>{`${
                        comment.from.user.username
                      } > ${comment.replyTo &&
                        comment.replyTo.user.username}`}</h6>
                    ))}

                  <div className={classes.comment}>{comment.text}</div>
                  <div className={classes.list}>
                    <li className={classes.days}>{postTime}</li>
                    {isPostClosed && (
                      <>
                        <li
                          className={classes.like}
                        >{`${comment.likes} likes`}</li>
                        <li
                          id="reply"
                          className={classes.reply}
                          onClick={() =>
                            onReplyClick(comment.id, comment.baseCommentId)
                          }
                        >
                          Reply
                        </li>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              {isPostClosed && !replies && (
                <Col xs={2} md={2}>
                  <img
                    className={classes.likeimg}
                    src={comment.liked ? like : unLike}
                    onClick={() => submitLike(comment.id)}
                    alt="user"

                  />
                </Col>
              )}
            </Row>
            <br />
            <br />
          </div>
        </Col>
        <Row>
          {comment &&
            comment.replies &&
            comment.replies.length > 0 &&
            comment.replies.map((comment, index) => (
              <div>
                <CommentAndLike
                  submitLike={submitLike}
                  comment={comment}
                  key={comment.id}
                  isPostClosed={isPostClosed}
                  onReplyClick={() => {
                    onReplyClick(comment.id, comment.baseCommentId);
                  }}
                  replies={true}
                  replyIndex={index}
                />{" "}
              </div>
            ))}
        </Row>
      </Row>
    </Container>
  );
};
export default CommentAndLike;
