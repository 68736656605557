import React from "react";
import { Col, FormControl } from "react-bootstrap";
import cross from "../../assets/icons/Cross.svg";
import EnableValidateIcon from "../../assets/icons/CheckboxPurple.svg";
const BirthyearInput = ({
  signUp,
  handleSignUpChange,
  handleSubmit,
  showAgeErrorMessage,
  validAge,
  setDobTyped,
}) => {
  return (
    <>
      <Col xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 className=" text-left text-bold label-text montserrat-font">
            Birth year
          </h4>

          <span className="agevalid-icon">
            {showAgeErrorMessage ? (
              <img src={!validAge && cross} />
            ) : (
              <img src={validAge && EnableValidateIcon} />
            )}
          </span>
        </div>
      </Col>
      <Col xs={12}>
        <FormControl
          type="text"
          pattern="\d*"
          maxLength="4"
          value={signUp.dob}
          className="form-control form-width no-shadow placeholder-text d-flex justify-content-center align-items-center custom-highlight"
          placeholder="Tap to enter your year!"
          onChange={(e) => {
            setDobTyped(e.target.value.length >= 2);
            handleSignUpChange("dob")(e);
            // handleSubmit();
          }}
          style={{
            padding: "0 50px",
          }}
        />

        {/* {showAgeErrorMessage ? (
          <img src={cross} />
        ) : (
          <img src={validAge && EnableValidateIcon} />
        )} */}

        {!validAge && showAgeErrorMessage && (
          <div>
            <div className="birth-error-message">
              <p className="mb-10 mt-10 text-center">
                {" "}
                Oh no! You can only register for Wizer if you’re 13 or older.!
              </p>
            </div>
          </div>
        )}
      </Col>
      <Col xs={12}>
        <p className="text-left  info-text-color montserrat-font ">
          This won't be shown publically
        </p>
      </Col>
    </>
  );
};

export default BirthyearInput;
