import BackButton from "../../assets/icons/BackButton.svg";
import { Col, Row } from "react-bootstrap";
import UserName from "../../assets/icons/PickUserName.svg";

import classes from "./index.module.css";
import React, { useEffect, useState } from "react";
import { checkCredential } from "../../api/Auth";
import UserExistMessage from "./UserExistMessage";
const SignUpUserName = (props) => {
  const { signUp, setType, handleSignUpChange } = props;
  const [userExist, setUserExist] = useState(true);
  useEffect(async () => {
    // setUserExist(true);
    if (signUp.username.length >= 4) {
      const result = await checkCredential({ username: signUp.username });
      if (result.result) {
        setUserExist(false);
      } else {
        setUserExist(true);
      }
    } else {
      setUserExist(true);
    }
  }, [signUp]);
  return (
    <> {userExist}
    
        <Row>
          <Col lg={7}>
            <div className={classes.pick_user_name_screen}>
              <Row>
                <Col>
                    <Row>
                      <Col xs={9} md={10}>
                        <div className={classes.back_button}>
                          <button
                            type="button"
                            onClick={() => {
                              setType(7);
                            }}
                            className={classes.btn}
                          >
                            <img src={BackButton} alt="back button" />
                          </button>
                        </div>
                      </Col>
                    </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={classes.user_name_icon}>
                    <img src={UserName} alt="name" />{" "}
                  </div>

                  <p className={classes.title}>Now pick a username!</p>

                  <div className={classes.input_field}>
                    <div className={classes.username}>
                      <input
                        type="text"
                        value={signUp.username}
                        placeholder="user name"
                        onChange={handleSignUpChange("username")}
                        className={classes.user_name}
                      />
                      <UserExistMessage signUp={signUp} userExist={userExist} />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className={classes.continue_button}>
                <button
                  type="submit"
                  disabled={userExist}
                  onClick={() => setType(9)}
                  className={classes.button_continue}
                >
                  <p>Continue</p>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
  );
};
export default SignUpUserName;
