import axios from "../../services/interceptor";
export const getCategories = async () => {
  const res = await axios.get("/categories");
  return res.data;
};

export const addCategories = async (payload) => {
  const res = await axios.patch("/users/edit/categories", payload);
  return res.data;
};
