import React from "react";
import QuestionCommentAndVote from "../QuestionCommentAndVote";

const QuestionContent = ({specificList, junkeeAdminQuestions, handleCommentClick,footercss }) => {
  return (
    <div>
      {Object.keys(junkeeAdminQuestions).length > 0 ? (
        <div>
          <QuestionCommentAndVote 
          footercss={footercss}
          specificList={specificList}
            questionData={junkeeAdminQuestions}
            handleCommentClick={handleCommentClick}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default QuestionContent;
