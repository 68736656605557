import axios from '../../services/interceptor'

export const commentLike = async (commentId) => {
  let res = await axios.post(`/posts/comments/add-like/${commentId}`)
  return res;
}

export const commentReply = async (postId, replyData) => {
  let res = await axios.post(`/posts/comments/replies/${postId}`, replyData)
  return res;
}