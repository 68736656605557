import React from "react";
import "./index.css";
import {  Row, Col } from "react-bootstrap";
const Loading = () => {
  return (
    <div className="app-wrapper">
      <Row>
        <Col lg={7}>
          <div className="load_spinner">
            <div className="radialProgressBar">
              <div className="overlay"></div>
            </div>
            <div className="spinner_text">
              <p>Loading</p>
            </div>
          </div>
        </Col>
      </Row></div>
  );
};
export default Loading;
